import {
  ApiTypesBulkCreateOrgPeopleReq,
  ApiTypesBulkDeleteOrgPeopleReq,
  ApiTypesBulkUpdateOrgPeopleReq,
  ApiTypesUpdateOrgPeopleReq,
} from '@bitkey-service/v2_core-types/lib/api/organization/people/apiTypesOrgPeople';
import {V2StoreTypesOrgPeople} from '@bitkey-service/v2_core-types/lib/store/organizations/people/v2_storeTypesOrgPeople';

import {createPeopleApi, CreatePeopleBody} from '@/api-call/workhub-core/createPeopleApi';

import {FirestoreOrgPeople} from '../../common/firebase/firestore/references/firestoreOrgPeople';
import {FirestoreOrgPeopleDeleted} from '../../common/firebase/firestore/references/firestoreOrgPeopleDeleted';
import {coreLogOption} from '../../v2_api/workhub-core/apiWorkhubCore';
import {V2ApiWorkhubCorePeople} from '../../v2_api/workhub-core/organizations/v2People/v2_ApiWorkhubCorePeople';

export class V2PeopleService {
  public static getSpaceAuthority = (peopleId: string) => V2ApiWorkhubCorePeople.getSpaceAuthority(peopleId);

  public static add = (data: CreatePeopleBody) => createPeopleApi({body: data}).then(r => r.data);

  public static bulkAdd = (data: ApiTypesBulkCreateOrgPeopleReq) => V2ApiWorkhubCorePeople.bulkAdd(data);

  public static update = (id: string, data: ApiTypesUpdateOrgPeopleReq, csv = false) =>
    V2ApiWorkhubCorePeople.update(id, {
      ...data,
      logOption: coreLogOption(csv),
    });

  public static bulkUpdate = (data: ApiTypesBulkUpdateOrgPeopleReq) => V2ApiWorkhubCorePeople.bulkUpdate(data);

  public static deleteById = async (peopleId: string) => {
    return await V2ApiWorkhubCorePeople.deleteById(peopleId);
  };
  public static sendInvitation = async ({ids}: {ids: string[]}) => {
    return await V2ApiWorkhubCorePeople.sendInvitation(ids);
  };

  public static deleteFaces = async (id: string) => {
    return await V2ApiWorkhubCorePeople.deleteFaces(id);
  };

  public static bulkDeleteFaces = async (data: ApiTypesBulkDeleteOrgPeopleReq) => {
    return await V2ApiWorkhubCorePeople.bulkDeleteFaces(data);
  };

  public static getByIdsWithDeleted = async (organizationId: string, peopleIds: string[]) => {
    const {0: people, 1: peopleDeleted} = await Promise.all([
      FirestoreOrgPeople.getByIds(organizationId, peopleIds),
      FirestoreOrgPeopleDeleted.getByIds(organizationId, peopleIds),
    ]);
    return [...people, ...peopleDeleted];
  };
  public static getByPersonaIdsWithDeleted = async (
    organizationId: string,
    personaIds: string[]
  ): Promise<(V2StoreTypesOrgPeople & {deleted?: boolean})[]> => {
    const {0: people, 1: peopleDeleted} = await Promise.all([
      FirestoreOrgPeople.getByPersonaIds(organizationId, personaIds),
      FirestoreOrgPeopleDeleted.getByPersonaIds(organizationId, personaIds),
    ]);
    return [...people, ...peopleDeleted];
  };
}
