import styled from '@emotion/styled';
import React, {memo, useCallback, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import {WHFont} from '@/common/styles/whFont';

import WFormDialogErrorLabel from './WFormDialogErrorLabel';

const Root = styled.div<{inValid?: boolean; isFocused: boolean; isDisabledColor?: boolean; width?: string}>`
  height: 32px;
  position: relative;
  /* disabled用のスタイル（border、backgroundを無しにして、paddingを調整する） */
  padding: ${props => (props.isDisabledColor ? '0 0px' : '0 8px')};
  width: ${props => (props.width ? props.width : '100%')};
  background: ${props => (props.isDisabledColor ? 'rgba(0, 0, 0, 0)' : 'var(--surface-neutral-middle)')};
  border: 2px solid
    ${props =>
      props.isDisabledColor
        ? 'rgba(0, 0, 0, 0)'
        : props.inValid
          ? 'var(--border-semantic-error)'
          : props.isFocused
            ? 'var(--border-brand-primary)'
            : 'var(--border-on-light-default)'};
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles<{isDisabledColor?: boolean}>()((_theme, props) => ({
  input: {
    border: 'none',
    background: 'none',
    width: '100%',
    color: 'var(--text-neutral-primary)',
    ...WHFont.bodyMedium,
    // disabled用のスタイル（textFieldではなくなるので、位置を調節する）
    position: 'relative',
    top: props.isDisabledColor ? '-15px' : '0px',
    left: props.isDisabledColor ? '-2px' : '0px',
  },
}));

// WTextFieldを参考にデザインのみ修正している（デザイントークン（WH.ColorとWH.Font）が当たっていなかったので当てる）
// 本来はWTextFieldを修正していくべきだが、コンポーネントを修正した場合、影響範囲が大きく検証しきれないため、新しいコンポーネントを作成する
const WFormDialogTextField = memo(function WFormDialogTextField({
  onBlurValue,
  onBlur,
  inValidMessage,
  isDisabledColor,
  width,
  endAdornment,
  ...props
}: {
  onBlurValue?: (value: string | number) => string | number;
  inValidMessage?: string;
  isDisabledColor?: boolean;
  width?: string;
  endAdornment?: React.ReactNode;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  const [isFocused, setIsFocused] = useState(false);
  const {classes} = useStyles({isDisabledColor});

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlurEvent = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlurValue) {
        onBlurValue(event.target.value);
      }
      if (onBlur) {
        onBlur(event);
      }
      setIsFocused(false);
    },
    [onBlur, onBlurValue]
  );

  return (
    <>
      <Root inValid={!!inValidMessage} isFocused={isFocused} isDisabledColor={isDisabledColor} width={width}>
        <input className={classes.input} onBlur={onBlurEvent} onFocus={handleFocus} {...props} />
        {endAdornment && <div css={{position: 'absolute', right: 12, height: '24px'}}>{endAdornment}</div>}
      </Root>
      {!!inValidMessage && <WFormDialogErrorLabel title={inValidMessage}></WFormDialogErrorLabel>}
    </>
  );
});

export default WFormDialogTextField;
