import styled from '@emotion/styled';
import {memo} from 'react';

import {WHColor} from '@/common/styles/whColor';
import {WHFont} from '@/common/styles/whFont';

const Title = styled(styled.div`
  color: ${WHColor.text.semanticError};
`)({
  ...WHFont.labelSmall,
});

const WFormDialogErrorLabel = memo(function WFormDialogErrorLabel({title}: {title: string}) {
  return <Title>{title}</Title>;
});

export default WFormDialogErrorLabel;
