import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import * as React from 'react';

import useDict from '@/common/hooks/useDict';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHColor} from '@/common/styles/whColor';
import {WHFont} from '@/common/styles/whFont';

const dictDef = {
  required: {
    default: {
      default: '必須',
      [Locale.en_US]: 'Required',
    },
  },
};

const StyledChip = styled(styled(Chip)`
  color: ${WHColor.text.semanticError};
  background-color: ${WHColor.surface.semanticErrorLight};
  border-radius: 32px;
  margin-left: 8px;

  & .MuiChip-label {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.MuiChip-root {
    height: 18px;
    margin-bottom: 1px;
  }
`)({...WHFont.labelSmallStrong});

const WRequiredChip: React.FC = () => {
  const dict = useDict(dictDef);

  return <StyledChip label={dict.required} />;
};

WRequiredChip.displayName = 'WRequiredChip';
export default React.memo(WRequiredChip);
