import Divider from '@mui/material/Divider';
import styled from '@mui/material/styles/styled';
import React, {type PropsWithChildren} from 'react';

import {WHColor} from '@/common/styles/whColor';

type P = {
  borderColor?: string;
  textAlign?: 'left' | 'center' | 'right';
  orientation?: 'horizontal' | 'vertical';
  variant?: 'fullWidth' | 'inset' | 'middle';
};

const StyledDivider = styled(Divider)<P>(({borderColor}) => ({
  borderColor: borderColor,
}));

const WDivider: React.FC<PropsWithChildren<P>> = props => {
  const {
    textAlign = 'center',
    orientation = 'horizontal',
    variant = 'fullWidth',
    borderColor = WHColor.border.onLightDefault,
    children,
  } = props;

  if (children) {
    return (
      <StyledDivider textAlign={textAlign} orientation={orientation} variant={variant} borderColor={borderColor}>
        {children}
      </StyledDivider>
    );
  } else {
    return (
      <StyledDivider textAlign={textAlign} orientation={orientation} variant={variant} borderColor={borderColor} />
    );
  }
};

export default WDivider;
