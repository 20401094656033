import React from 'react';

import {WHColor} from '@/common/styles/whColor';

/** @deprecated components/icon へ移行 */
const WorkhubLogoIcon = React.memo<{size: number}>(function WorkhubLogoIcon({size}) {
  return (
    <svg width={size ? size : '123'} height='133' viewBox='0 0 125 133' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='21.1093' y='0.5' width='82.6461' height='82.6461' rx='12' fill={WHColor.object.brandPrimary} />
      <path
        d='M70.144 58.3198C69.6969 58.3198 69.2995 58.1098 69.1836 57.8191L63.1391 45.011C62.9735 44.6718 62.6257 44.4457 62.2283 44.4457C61.8474 44.4457 61.4831 44.6718 61.3175 45.011L55.3227 57.8191C55.2068 58.0775 54.8756 58.3198 54.4285 58.3198H54.1469C53.6998 58.3198 53.3024 58.0937 53.1864 57.7706L46.4796 37.0484C46.314 36.6607 46.314 36.3538 46.463 36.1439C46.6121 35.9178 46.9101 35.7885 47.2745 35.7885H50.3215C50.7024 35.7885 51.2323 36.0631 51.3317 36.4508L54.3622 46.2062C54.4781 46.5939 54.8425 46.8684 55.2399 46.9007C55.2565 46.9007 55.2896 46.9007 55.3061 46.9007C55.687 46.9007 56.0513 46.6746 56.2169 46.3354L61.185 35.7885C61.3009 35.4978 61.7149 35.2394 62.1455 35.2394H62.4767C62.9238 35.2394 63.3047 35.4494 63.4372 35.7885L68.339 46.3516C68.5046 46.7069 68.8524 46.9169 69.2498 46.9169C69.2664 46.9169 69.2995 46.9169 69.316 46.9169C69.73 46.8846 70.0944 46.61 70.2103 46.2224L73.1911 36.4508C73.423 35.9178 74.0191 35.7885 74.9299 35.7885H77.1324C77.5133 35.7885 77.828 35.9178 77.977 36.16C78.126 36.4023 78.126 36.7253 77.977 37.0484L71.3861 57.7706C71.2701 58.0937 70.8893 58.3198 70.3759 58.3198H70.144Z'
        fill='white'
      />
      <path
        d='M79.5421 30.2032C79.5421 27.3968 81.7919 25.1412 84.591 25.1412C87.3639 25.1412 89.6398 27.3968 89.6398 30.2032C89.6398 33.0095 87.3639 35.2389 84.591 35.2389C81.7919 35.2389 79.5421 33.0095 79.5421 30.2032ZM87.5732 30.2032C87.5732 28.6557 86.1082 27.2132 84.591 27.2132C82.9952 27.2132 81.5303 28.682 81.5303 30.2032C81.5303 31.8031 82.9952 33.2718 84.591 33.2718C86.1082 33.2718 87.5732 31.8031 87.5732 30.2032Z'
        fill='white'
      />
      <path
        d='M17.1343 132.792C16.8123 132.792 16.5261 132.637 16.4426 132.423L12.0893 122.965C11.9701 122.714 11.7196 122.547 11.4334 122.547C11.159 122.547 10.8966 122.714 10.7774 122.965L6.45989 132.423C6.3764 132.613 6.13787 132.792 5.81585 132.792H5.61309C5.29107 132.792 5.00483 132.625 4.92134 132.387L0.0910004 117.085C-0.0282672 116.799 -0.0282672 116.572 0.0790737 116.417C0.186414 116.25 0.401096 116.155 0.663485 116.155H2.85801C3.13232 116.155 3.51398 116.357 3.58554 116.644L5.76814 123.847C5.85163 124.134 6.11401 124.336 6.40026 124.36C6.41218 124.36 6.43604 124.36 6.44796 124.36C6.72228 124.36 6.98467 124.193 7.10394 123.943L10.682 116.155C10.7655 115.94 11.0636 115.749 11.3737 115.749H11.6123C11.9343 115.749 12.2086 115.904 12.304 116.155L15.8343 123.955C15.9536 124.217 16.2041 124.372 16.4903 124.372C16.5022 124.372 16.5261 124.372 16.538 124.372C16.8362 124.348 17.0986 124.145 17.182 123.859L19.3289 116.644C19.4958 116.25 19.9252 116.155 20.5812 116.155H22.1674C22.4417 116.155 22.6684 116.25 22.7757 116.429C22.883 116.608 22.883 116.846 22.7757 117.085L18.0288 132.387C17.9454 132.625 17.671 132.792 17.3013 132.792H17.1343Z'
        fill={WHColor.object.brandPrimary}
      />
      <path
        d='M31.8639 132.792C27.3437 132.792 23.6702 128.952 23.6702 124.229C23.6702 119.554 27.3437 115.749 31.8639 115.749C36.3842 115.749 40.0576 119.554 40.0576 124.229C40.0576 128.952 36.3842 132.792 31.8639 132.792ZM31.8639 119.279C29.3355 119.279 27.2721 121.498 27.2721 124.229C27.2721 127.02 29.3355 129.298 31.8639 129.298C34.3924 129.298 36.4557 127.02 36.4557 124.229C36.4438 121.498 34.3924 119.279 31.8639 119.279Z'
        fill={WHColor.object.brandPrimary}
      />
      <path
        d='M43.2659 132.387C42.8604 132.387 42.4907 132.017 42.4907 131.612V116.93C42.4907 116.524 42.8604 116.154 43.2659 116.154H44.4467C44.6852 116.154 44.9357 116.321 45.0669 116.56C45.2219 116.846 45.5082 117.061 45.8302 117.132C45.9137 117.144 45.9852 117.156 46.0687 117.156C46.3192 117.156 46.5577 117.073 46.7605 116.93C47.7981 116.154 48.9908 115.761 50.3266 115.761C51.7817 115.761 52.9028 116.071 53.2486 116.584C53.3798 116.787 53.4037 117.013 53.2964 117.264L52.3184 119.255C52.1872 119.554 51.9606 119.697 51.6862 119.697C51.567 119.697 51.4596 119.673 51.3404 119.625C51.1018 119.518 50.5293 119.339 49.9569 119.339C48.0486 119.339 46.9394 120.484 46.5458 120.973C46.3908 121.176 46.2953 121.426 46.2953 121.689V131.635C46.2953 131.957 46.2953 132.411 45.1981 132.411H43.2659V132.387Z'
        fill={WHColor.object.brandPrimary}
      />
      <path
        d='M67.2864 132.387C67.0002 132.387 66.6662 132.22 66.5589 132.065L61.1918 126.543C60.9652 126.316 60.667 126.197 60.3689 126.197C60.2257 126.197 60.0707 126.221 59.9395 126.28C59.5101 126.459 59.2239 126.877 59.2239 127.342V131.612C59.2239 132.017 58.8542 132.387 58.4487 132.387H56.0991C55.6936 132.387 55.3238 132.017 55.3238 131.612V104.753C55.3238 104.347 55.6936 103.977 56.0991 103.977H58.4367C58.8422 103.977 59.212 104.347 59.212 104.753V119.744C59.212 120.198 59.4744 120.615 59.8918 120.794C60.0468 120.866 60.2019 120.889 60.3569 120.889C60.6313 120.889 60.9056 120.794 61.1203 120.591L65.5332 116.632C65.7359 116.453 66.0937 116.143 66.5469 116.143H69.3855C69.5525 116.143 69.8626 116.19 69.9938 116.524C70.0892 116.775 70.0295 117.073 69.8387 117.24L63.9946 122.559C63.768 122.774 63.6249 123.072 63.6249 123.382C63.6129 123.692 63.7322 124.002 63.9588 124.229L71.0553 131.325C71.2461 131.516 71.3057 131.802 71.2103 132.029C71.1149 132.256 70.9002 132.375 70.602 132.375H67.2864V132.387Z'
        fill={WHColor.object.brandPrimary}
      />
      <path
        d='M85.9517 132.387C85.5223 132.387 85.1765 132.041 85.1765 131.612V123.37C85.1765 120.806 83.8884 119.267 81.7296 119.267C79.6663 119.267 78.08 120.579 77.5314 121.462C77.4121 121.641 77.3525 121.856 77.3525 122.082V131.612C77.3525 132.089 77.0424 132.387 76.5415 132.387H74.2277C73.8222 132.387 73.4525 132.017 73.4525 131.612V104.753C73.4525 104.347 73.8222 103.977 74.2277 103.977H76.5892C76.9947 103.977 77.3644 104.347 77.3644 104.753V115.475C77.3644 115.868 77.5672 116.238 77.9011 116.453C78.092 116.572 78.3067 116.632 78.5094 116.632C78.6764 116.632 78.8434 116.596 79.0103 116.524C80.0837 116.011 81.1333 115.761 82.2306 115.761C86.4765 115.761 89.005 118.647 89.005 123.478V131.635C89.005 132.041 88.6352 132.411 88.2297 132.411H85.9517V132.387Z'
        fill={WHColor.object.brandPrimary}
      />
      <path
        d='M97.962 132.792C93.716 132.792 91.3903 130.073 91.3903 125.123V116.93C91.3903 116.524 91.76 116.155 92.1656 116.155H94.4793C94.8849 116.155 95.2546 116.524 95.2546 116.93V125.255C95.2546 127.831 96.4711 129.31 98.5821 129.31C100.741 129.31 102.148 127.712 102.494 126.936C102.566 126.793 102.601 126.626 102.601 126.459V116.918C102.601 116.345 102.828 116.143 103.496 116.143H105.607C106.013 116.143 106.382 116.512 106.382 116.918V131.612C106.382 132.017 106.013 132.387 105.607 132.387H104.474C104.045 132.387 103.89 132.065 103.83 131.957C103.675 131.659 103.412 131.445 103.09 131.361C102.995 131.337 102.9 131.325 102.804 131.325C102.578 131.325 102.351 131.397 102.16 131.528C100.884 132.375 99.5124 132.792 97.962 132.792Z'
        fill={WHColor.object.brandPrimary}
      />
      <path
        d='M117.403 132.792C115.673 132.792 114.314 132.124 113.467 131.552C113.276 131.421 113.049 131.349 112.823 131.349C112.727 131.349 112.644 131.361 112.549 131.385C112.238 131.457 111.964 131.671 111.809 131.957C111.654 132.244 111.439 132.387 111.189 132.387H109.972C109.567 132.387 109.197 132.017 109.197 131.612V104.753C109.197 104.347 109.567 103.977 109.972 103.977H112.334C112.739 103.977 113.109 104.347 113.109 104.753V114.998C113.109 115.355 113.276 115.701 113.574 115.916C113.777 116.071 114.016 116.143 114.266 116.143C114.373 116.143 114.481 116.131 114.588 116.095C115.387 115.856 116.21 115.737 117.009 115.737C121.494 115.737 125 119.482 125 124.253C124.988 129.047 121.649 132.792 117.403 132.792ZM116.58 119.279C115.351 119.279 114.361 119.589 113.753 119.852C113.324 120.031 113.049 120.448 113.049 120.913V126.578C113.049 126.793 113.109 126.996 113.216 127.175C113.515 127.676 114.683 129.298 116.902 129.298C119.728 129.298 121.207 126.769 121.207 124.265C121.219 121.426 119.228 119.279 116.58 119.279Z'
        fill={WHColor.object.brandPrimary}
      />
    </svg>
  );
});

export default WorkhubLogoIcon;
