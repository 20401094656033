import {listOrganizationAnnouncementsApi} from '@/api-call/workhub-core/listOrganizationAnnouncementsApi';
import {readAnnouncementApi} from '@/api-call/workhub-core/readAnnouncementApi';

import {FirebaseFunctions} from '../../common/firebase/functions/firebase-functions';
import Logger from '../../common/logger/logger';
import {AnnouncementState} from '../../common/redux/state-types/announcementState';

const logger = Logger.create('notification-service');

export class NotificationService {
  public static loadAnnouncements = async (): Promise<AnnouncementState[]> => {
    FirebaseFunctions.initIfNot();
    const announcements = (await listOrganizationAnnouncementsApi()).data;
    if (announcements?.length) {
      return announcements
        .filter(announcement => !!announcement.businessTypes[0])
        .map(a => ({
          announcementId: a.id,
          announcementType: a.announcementType,
          title: a.title,
          description: a.description,
          content: a.content,
          attachedImageUrlList: a.attachedImageUrlList,
          status: a.status,
          read: a.read,
          readAt: a.readAt,
          publishedAt: a.publishedAt ? a.publishedAt : 0,
          publishScheduledAt: a.publishScheduledAt ? a.publishScheduledAt : 0,
          closeScheduledAt: a.closeScheduledAt ? a.closeScheduledAt : 0,
        }))
        .sort((a, b) => {
          return a.publishScheduledAt < b.publishScheduledAt ? 1 : -1;
        });
    } else {
      return [];
    }
  };

  /**
   * お知らせ情報の既読処理
   * @param announcementId
   */
  public static readAnnouncement = async (announcementId: string) => {
    await readAnnouncementApi({
      paths: {announcementId},
    }).catch(e => {
      logger.error('Failed to update read announcement.', e);
    });
  };
}
