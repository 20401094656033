import {FeatureAuthority} from '@bitkey-service/workhub-types/lib/firestore/organizations/authority-pattern/firestoreTypesOrganizationsAuthorityPattern';

import {ApiAccountAuthorityPattern} from '@/api/account/authority-pattern/apiAccountAuthorityPattern';
import {
  ActivationFeatureGroupMap,
  ActivationGroup,
  Feature,
  FeatureGroup,
} from '@/common/feature-control/featureDefinitions';
import {MainMenuDictKey} from '@/wscreens/routing/def/MainMenuDictDef';

import ArrayUtil from '../../common/utils/arrayUtil';

type AuthorityColumn = {
  id: string;
  code: string;
  nameJp: string;
  nameEn: string;
  // authority: FeatureAuthority[];
};

export type AuthorityWithoutChildren = {
  dictKey: MainMenuDictKey | '';
  featureGroup?: FeatureGroup;
  feature?: Feature;
  deviceType?: 'bitlock' | 'bitreader' | 'bitlink';
  type: 'card';
};

export type AuthorityWithChildren = {
  dictKey: MainMenuDictKey | '';
  children: AuthorityWithoutChildren[];
  type: 'group';
};

export type AuthorityType = AuthorityWithChildren | AuthorityWithoutChildren;

/**
 * workhubAppからのデバイス設置系の権限まとめ。
 * Feature.WorkhubAppDeviceSettingと連動させたいので定義しておく
 */
export const workhubAppDeviceSettingFeatures: Feature[] = [
  //bitlock
  Feature.WorkhubAppInstallBitlockPro2,
  Feature.WorkhubAppInstallBitlockGate,
  Feature.WorkhubAppUninstallBitlockSeries,
  Feature.WorkhubAppFirmwareUpdateBitlockSeries,
  Feature.WorkhubAppViewLogsBitlockSeries,
  Feature.WorkhubAppSetUnlockPositionBitlockSeries,
  Feature.WorkhubAppAutoLockSettingBitlockSeries,
  Feature.WorkhubAppTouchSensorSettingBitlockSeries,
  //bitreader
  Feature.WorkhubAppInstallBitreaderPlus,
  Feature.WorkhubAppUninstallBitreaderSeries,
  Feature.WorkhubAppFirmwareUpdateBitreaderSeries,
  Feature.WorkhubAppViewLogsBitreaderSeries,
  Feature.WorkhubAppSwitchValidLockUnlockSettingBitreaderSeries,
  Feature.WorkhubAppEnableNfcSettingBitreaderSeries,
  Feature.WorkhubAppEnableKeyPadSettingBitreaderSeries,
  Feature.WorkhubAppEnableLedSettingBitreaderSeries,
  Feature.WorkhubAppBatteryAlertSettingBitreaderSeries,
  Feature.WorkhubAppPasscodeSettingBitreaderSeries,
  //bitlink
  Feature.WorkhubAppInstallBitlinkSeries,
  Feature.WorkhubAppUninstallBitlinkSeries,
  Feature.WorkhubAppFirmwareUpdateBitlinkSeries,
  Feature.WorkhubAppEnableBeaconSettingBitlinkSeries,
  Feature.WorkhubAppChangeWiFiConnectionSettingBitlinkSeries,
  //reader,link共通
  Feature.WorkhubAppLinkageDeviceSetting,
];

const FEATURE_NOT_IN_MENU: AuthorityType[] = [
  {
    dictKey: 'examine',
    children: [
      {
        dictKey: '',
        feature: Feature.CongestionStatus,
        featureGroup: FeatureGroup.CongestionMap,
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.LocationInformationCSVOutput,
        featureGroup: FeatureGroup.V2MemberManagement,
        type: 'card',
      },
    ],
    type: 'group',
  },
  {
    dictKey: 'setting',
    children: [
      {
        dictKey: '',
        feature: Feature.SpaceAuthoritySetting,
        featureGroup: FeatureGroup.SpaceAuthoritySetting,
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.SecuritySetting,
        featureGroup: FeatureGroup.SecuritySetting,
        type: 'card',
      },
    ],
    type: 'group',
  },
  {
    dictKey: 'bitlockApp',
    children: [
      {
        dictKey: '',
        feature: Feature.V2BitlockApp,
        featureGroup: FeatureGroup.V2BitlockApp,
        type: 'card',
      },
    ],
    type: 'group',
  },
  {
    dictKey: 'receptionApp',
    children: [
      {
        dictKey: '',
        feature: Feature.V2ReceptionApp,
        featureGroup: FeatureGroup.V2ReceptionApp,
        type: 'card',
      },
    ],
    type: 'group',
  },
  {
    dictKey: 'workhubApp',
    children: [
      {
        dictKey: '',
        feature: Feature.V2WorkhubAppOrganization,
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppPersonalizedNfcCard,
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.AzbilCloudOperation,
        featureGroup: FeatureGroup.AzbilCloudOperation,
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppDeviceSetting,
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppInstallBitlockPro2,
        deviceType: 'bitlock',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppUninstallBitlockSeries,
        deviceType: 'bitlock',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppFirmwareUpdateBitlockSeries,
        deviceType: 'bitlock',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppViewLogsBitlockSeries,
        deviceType: 'bitlock',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppSetUnlockPositionBitlockSeries,
        deviceType: 'bitlock',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppAutoLockSettingBitlockSeries,
        deviceType: 'bitlock',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppTouchSensorSettingBitlockSeries,
        deviceType: 'bitlock',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppInstallBitreaderPlus,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppUninstallBitreaderSeries,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppFirmwareUpdateBitreaderSeries,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppViewLogsBitreaderSeries,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppLinkageDeviceSetting,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppSwitchValidLockUnlockSettingBitreaderSeries,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppEnableNfcSettingBitreaderSeries,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppEnableKeyPadSettingBitreaderSeries,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppEnableLedSettingBitreaderSeries,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppBatteryAlertSettingBitreaderSeries,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppPasscodeSettingBitreaderSeries,
        deviceType: 'bitreader',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppInstallBitlinkSeries,
        deviceType: 'bitlink',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppUninstallBitlinkSeries,
        deviceType: 'bitlink',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppFirmwareUpdateBitlinkSeries,
        deviceType: 'bitlink',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppLinkageDeviceSetting,
        deviceType: 'bitlink',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppEnableBeaconSettingBitlinkSeries,
        deviceType: 'bitlink',
        type: 'card',
      },
      {
        dictKey: '',
        feature: Feature.WorkhubAppChangeWiFiConnectionSettingBitlinkSeries,
        deviceType: 'bitlink',
        type: 'card',
      },
    ],
    type: 'group',
  },
  {
    dictKey: 'remoteOperation',
    children: [
      {
        dictKey: '',
        feature: Feature.TabletOperation,
        featureGroup: FeatureGroup.TabletOperation,
        type: 'card',
      },
    ],
    type: 'group',
  },
  {
    dictKey: 'onlyBitkey',
    children: [
      {
        dictKey: '',
        feature: Feature.ThirdPlaceBceSetting,
        featureGroup: FeatureGroup.ThirdPlaceBceSetting,
        type: 'card',
      },
    ],
    type: 'group',
  },
];

export class AuthorityScreenService {
  // 画面で使う関数は参照変えたくないのでstatic
  public static loadNext = async ({id}) => {
    const result = await ApiAccountAuthorityPattern.getNext(id);
    return result.map(r => ({
      id: r.id,
      code: r.code,
      nameJp: r.nameJp,
      nameEn: r.nameEn,
    }));
  };

  public static toRow = (data: AuthorityColumn) => {
    return data;
  };

  /**
   * メニューに出しているfeatureはroute.tsから取得しており、
   * メニューに出していないものは別定義になっているのでマージする
   * @param param0
   * @returns
   */
  public static getMergedFeatures = ({
    activationGroups,
    authority,
    authoritiesFromMenu,
  }: {
    activationGroups: ActivationGroup[];
    authority?: FeatureAuthority;
    authoritiesFromMenu: AuthorityType[];
  }): AuthorityType[] => {
    const featuresNotInMenu = AuthorityScreenService.getFeaturesNotDisplayInMenu(activationGroups, authority);
    //メニューにdictKeyが既にある場合はchildrenをマージする。それいがいは最後尾に付加する
    const existKeyNoMenuFeatures = featuresNotInMenu.filter(feature =>
      authoritiesFromMenu.some(menuAuthority => menuAuthority.dictKey === feature.dictKey)
    );
    const existFeatureMap = existKeyNoMenuFeatures.reduce<{[dictKey: string]: AuthorityType[]}>((pre, cur) => {
      if (pre[cur.dictKey]) {
        pre[cur.dictKey].push(cur);
      } else {
        pre[cur.dictKey] = [cur];
      }

      return pre;
    }, {});

    const notExistKeyInMenuFeatures = featuresNotInMenu.filter(
      feature => !existKeyNoMenuFeatures.some(exist => exist.dictKey === feature.dictKey)
    );

    const mergedFeatures = authoritiesFromMenu.map(authority => {
      const relatedNoMenuFeature = existFeatureMap[authority.dictKey]?.filter(
        (authority): authority is AuthorityWithChildren => authority.type === 'group'
      );

      if (!relatedNoMenuFeature?.length) {
        return authority;
      }

      if (authority.type === 'card') {
        //childrenがないもの(=card)は、マージできないのでスキップ。
        return authority;
      }

      return {
        ...authority,
        children: [...authority.children, ...relatedNoMenuFeature.flatMap(r => r.children)],
      };
    });

    return [...mergedFeatures, ...notExistKeyInMenuFeatures];
  };

  private static getFeaturesNotDisplayInMenu = (
    activationGroups: ActivationGroup[],
    authority?: FeatureAuthority
  ): AuthorityType[] => {
    const enableFeatures = authority
      ? new Set(
          ArrayUtil.removeUndefined(
            Object.entries(authority).map(([feature, authorityLevel]) => {
              if (authorityLevel === 'write') {
                return feature as Feature;
              }

              return undefined;
            })
          )
        )
      : undefined;

    const targetActivationGroupSet = new Set(activationGroups);
    const activatedFeatureGroups = new Set(
      Object.entries(ActivationFeatureGroupMap)
        .filter(([_, relatedActivationGroups]) => relatedActivationGroups.some(ag => targetActivationGroupSet.has(ag)))
        .map(([featureGroup]) => featureGroup as FeatureGroup)
    );

    return ArrayUtil.removeUndefined(
      FEATURE_NOT_IN_MENU.map(authority => {
        if (authority.type === 'group') {
          const children = authority.children.filter(
            child =>
              child.feature &&
              (!enableFeatures || enableFeatures.has(child.feature)) &&
              (!child.featureGroup || activatedFeatureGroups.has(child.featureGroup))
          );

          if (children.length) {
            return {
              ...authority,
              children,
            };
          }

          return undefined;
        }

        if (
          authority.feature &&
          (!enableFeatures || enableFeatures.has(authority.feature)) &&
          (!authority.featureGroup || activatedFeatureGroups.has(authority.featureGroup))
        ) {
          return authority;
        }

        return undefined;
      })
    );
  };
}
