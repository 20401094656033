import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';
import { Button as ButtonBase } from 'react-aria-components';
import { Ripple } from '../ripple';
import { Tooltip } from '../tooltip';
import styles from './IconButton.module.css';
const _IconButton = ({ title, description, background = false, disruptive = false, disabled, disableTooltip, className, onClick, children }, ref) => {
    const inner = useMemo(() => (_jsxs(ButtonBase, { ref: ref, className: clsx(styles.root, className), isDisabled: disabled, "aria-label": title, "data-background": background || null, "data-disruptive": disruptive || null, onPress: onClick, children: [children, _jsx(Ripple, {})] })), [ref, className, disabled, title, background, disruptive, onClick, children]);
    if (disableTooltip) {
        return inner;
    }
    return (_jsxs(Tooltip.Trigger, { children: [inner, _jsx(Tooltip, { children: description || _jsx("span", { "aria-hidden": true, children: title }) })] }));
};
export const IconButton = forwardRef(_IconButton);
