import {
  ApiTypesBulkCreateOrgPeopleReq,
  ApiTypesBulkCreateOrgPeopleRes,
  ApiTypesBulkDeleteOrgPeopleReq,
  ApiTypesBulkUpdateOrgPeopleReq,
  ApiTypesBulkUpdateOrgPeopleRes,
  ApiTypesCreateOrgPeopleReq,
  ApiTypesCreateOrgPeopleRes,
  ApiTypesUpdateOrgPeopleReq,
  ApiTypesUpdateOrgPeopleRes,
} from '@bitkey-service/v2_core-types/lib/api/organization/people/apiTypesOrgPeople';
import {ApiTypesOrgPeopleAuthorityGetRes} from '@bitkey-service/v2_core-types/lib/api/organization/people/authority/apiTypesOrgPeopleAuthority';
import {AxiosRequestConfig} from 'axios';

import {catchStack} from '@/common/error/stacktrace';
import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import {ApiWorkhubCore} from '../../apiWorkhubCore';

const basePath = () => {
  return `organizations/people`;
};

export class V2ApiWorkhubCorePeople {
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.post<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config).catch(
      catchStack()
    );
  private static get = <Response>(path: string) =>
    ApiWorkhubCore.get<Response>(FirebaseFunctions.makePath(basePath(), path)).catch(catchStack());
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.patch<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config).catch(
      catchStack()
    );
  private static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.delete<Response>(FirebaseFunctions.makePath(basePath(), path), config).catch(catchStack());

  public static getSpaceAuthority = (peopleId: string) =>
    V2ApiWorkhubCorePeople.get<ApiTypesOrgPeopleAuthorityGetRes>(`/${peopleId}/authority-space`);

  public static add = (data: ApiTypesCreateOrgPeopleReq) =>
    V2ApiWorkhubCorePeople.post<ApiTypesCreateOrgPeopleRes, ApiTypesCreateOrgPeopleReq>('/', data);

  public static bulkAdd = (data: ApiTypesBulkCreateOrgPeopleReq) =>
    V2ApiWorkhubCorePeople.post<ApiTypesBulkCreateOrgPeopleRes, ApiTypesBulkCreateOrgPeopleReq>('/bulk', data);

  public static update = (peopleId: string, data: Partial<ApiTypesUpdateOrgPeopleReq>) =>
    V2ApiWorkhubCorePeople.patch<ApiTypesUpdateOrgPeopleRes, Partial<ApiTypesUpdateOrgPeopleReq>>('/' + peopleId, data);

  public static bulkUpdate = (data: ApiTypesBulkUpdateOrgPeopleReq) =>
    V2ApiWorkhubCorePeople.patch<ApiTypesBulkUpdateOrgPeopleRes, ApiTypesBulkUpdateOrgPeopleReq>('/bulk', data);

  public static deleteById = (peopleId: string) => V2ApiWorkhubCorePeople.delete<{id: string}>('/' + peopleId);

  public static deleteFaces = (peopleId: string) => V2ApiWorkhubCorePeople.delete<{id: string}>(`/faces/${peopleId}`);

  public static bulkDeleteFaces = (data: ApiTypesBulkDeleteOrgPeopleReq) =>
    V2ApiWorkhubCorePeople.post<void, ApiTypesBulkDeleteOrgPeopleReq>('bulk/faces', data);

  public static sendInvitation = (ids: string[]) =>
    V2ApiWorkhubCorePeople.post<any[], string[]>('/invitation/resend', ids);
}
