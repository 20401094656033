import {AxiosRequestConfig} from 'axios';

import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';

import {getAccessToken} from '../../common/accessToken';
import {accountApi} from '../../common/axios/accountApi';
import {getFirebaseAuth} from '../../common/firebase/firebase-auth';

const getIdToken = async () => {
  // getFirebaseAuth().currentUserが間に合わずに空の場合があるのでonAuthStateChangedで待つ
  return new Promise((resolve, reject) => {
    const unsubscribe = getFirebaseAuth().onAuthStateChanged(
      u => {
        if (u) {
          resolve(u?.getIdToken());
        } else {
          resolve(undefined);
        }
        unsubscribe();
      },
      err => {
        if (err) {
          reject(err);
        }
        unsubscribe();
      }
    );
  });
};

const getConfig = async (config?: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const idToken = await getIdToken();
  const authorizationHeader = idToken ? {authorization: `bearer ${idToken}`} : undefined;
  const accessToken = getAccessToken();
  const xApiKeyHeader = accessToken ? {'x-api-key': accessToken} : undefined;
  return {...config, headers: {...config?.headers, ...authorizationHeader, ...xApiKeyHeader}};
};

export class ApiAccount {
  static post = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await accountApi.post(path, data, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };
  static put = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await accountApi.put(path, data, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };
  static patch = async <Response, Request>(
    path: string,
    data: Request,
    config?: AxiosRequestConfig
  ): Promise<Response> => {
    const res = await accountApi.patch(path, data, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };
  static get = async <Response>(path: string, config?: AxiosRequestConfig): Promise<Response> => {
    const res = await accountApi.get(path, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };
  static delete = async <Response>(path: string, config?: AxiosRequestConfig): Promise<Response> => {
    const res = await accountApi.delete(path, await getConfig(config)).catch(axiosCatch(getStackTrace()));
    return res.data;
  };
}
