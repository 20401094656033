// tabIndexむりやろ

import styled from '@emotion/styled';
import MuiTab, {TabProps as MuiTabProps} from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import React, {type SyntheticEvent, useCallback, useMemo, useRef} from 'react';
import {withStyles} from 'tss-react/mui';

import Logger from '@/common/logger/logger';
import {WHColor} from '@/common/styles/whColor';
import Tooltip from '@/components/tooltip/Tooltip';

const HeaderDiv = styled.div<{paddingLeft?: string; backGroundColor?: string}>`
  display: flex;
  height: 48px;
  background-color: ${props => (props.backGroundColor ? props.backGroundColor : WHColor.surface.neutralLow)};
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-left: ${props => props.paddingLeft ?? '24px'};
  justify-content: space-between;
`;

const Tabs = withStyles(MuiTabs, () => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
    backgroundColor: 'transparent',
    '& > span': {
      width: 'calc(100% - 22px)',
      backgroundColor: WHColor.surface.brandPrimary,
    },
  },
  root: {
    height: '100%',
  },
  flexContainer: {
    height: '100%',
  },
}));

const Tab = withStyles(MuiTab, () => ({
  root: {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 16,
    paddingRight: 12,
    paddingLeft: 12,
    marginLeft: 8,
    marginRight: 8,
    minWidth: 63,
    '&:focus': {
      opacity: 1,
    },
  },
}));

const isHtmlElement = (elm: any): elm is HTMLElement => {
  return typeof elm.offsetWidth === 'number' && typeof elm.scrollWidth === 'number';
};

const LabelWrapper = styled.span`
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: inherit;
`;

// 省略表示のときだけTooltipを表示するTab
const TabWithConditionalTooltip: React.FC<
  MuiTabProps & {
    tooltipLabel?: string;
    tooltipPlacement?:
      | 'bottom-end'
      | 'bottom-start'
      | 'bottom'
      | 'left-end'
      | 'left-start'
      | 'left'
      | 'right-end'
      | 'right-start'
      | 'right'
      | 'top-end'
      | 'top-start'
      | 'top';
  }
> = ({label, tooltipLabel, tooltipPlacement, ...rest}) => {
  const tabRef = useRef<HTMLDivElement>(null);
  const notDisplayedEllipsis = useCallback(() => {
    if (!tabRef.current?.firstElementChild) {
      return true;
    }

    const child = tabRef.current.firstElementChild;
    // Element型にoffsetWidthがないのでtype guardを入れる
    if (!isHtmlElement(child)) {
      return true;
    }

    // 要素幅と scrollWidth（テキスト幅）が同一、あるいは前者のほうが大きいときは
    // text-overflow: ellipsis が適用されない（省略表示されない）状態と判断する
    return child.offsetWidth >= child.scrollWidth;
  }, []);

  /**
   * MUIv5からlabelはspanでラップされなくなり、
   * text-overflow: ellipsisが効かなくなったので、
   * ここでラップする
   */
  const tabLabel = useMemo(() => <LabelWrapper>{label}</LabelWrapper>, [label]);

  return (
    <Tooltip
      title={<>{tooltipLabel ?? label}</>}
      disableHoverListener={tooltipLabel ? false : notDisplayedEllipsis()} // tooltipLabelがあるときにはfalseにしたいのでこの形にしている
      placement={tooltipPlacement}
    >
      <Tab ref={tabRef} label={tabLabel} {...rest} />
    </Tooltip>
  );
};

export type WTabProps = {
  tabs: {
    id: string;
    label: React.ReactNode;
    tooltip?: {
      label: string;
      placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    };
  }[];
  tabId: string;
  paddingLeft?: string;
  onChange: (tabId: string) => any;
  backGroundColor?: string;
  tabIndicatorAdditionalProps?: Partial<React.HTMLAttributes<HTMLDivElement>>;
};

const logger = Logger.create('WHeaderTab2');
const WHeaderTab2: React.FC<WTabProps> = props => {
  const onChange = useCallback(
    (_: SyntheticEvent, v: number) => {
      const tab = props.tabs[v];
      if (tab) {
        props.onChange(tab.id);
      } else {
        logger.error('undefined tab id.', new Error(), {tabId: v});
      }
    },
    [props]
  );

  const TabIndicatorProps = {
    children: <span />,
    ...props.tabIndicatorAdditionalProps,
  };

  return (
    <HeaderDiv paddingLeft={props.paddingLeft} backGroundColor={props.backGroundColor} data-testid='rootWHeaderTab2'>
      <Tabs
        value={props.tabs.findIndex(t => t.id === props.tabId)}
        onChange={onChange}
        TabIndicatorProps={TabIndicatorProps}
        variant={'scrollable'}
      >
        {props.tabs.map(tab => (
          <TabWithConditionalTooltip
            key={tab.id}
            label={tab.label}
            tooltipLabel={tab.tooltip?.label}
            tooltipPlacement={tab.tooltip?.placement}
          />
        ))}
      </Tabs>
    </HeaderDiv>
  );
};

export default React.memo(WHeaderTab2);
