// generated-by-gen
import type {AxiosResponse} from 'axios';

import {getCoreAxios} from '@/common/axios/getCoreAxios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
import Logger from '@/common/logger/logger';

const logger = Logger.create('createPeopleApi');

export const createPeopleApi = async (arg: {body: CreatePeopleBody}, opts?: {region?: 'default' | 'asia'}) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  const idToken = await getFirebaseIdToken();
  return await axios
    .post<CreatePeopleResponse>(`/organizations/people`, arg.body, {
      headers: {
        authorization: `bearer ${idToken}`,
      },
    })
    .catch(axiosCatch(getStackTrace()));
};

export type CreatePeopleResponse = {
  /**
   * コード
   */
  code: string;
  /**
   * 管理コード
   * 特定の使い方はなく、任意のコードを People へ付与したい場合に利用する
   *
   */
  managedCode?: string;
  /**
   * 氏名
   */
  nameJp: string;
  /**
   * 氏名 (EN)
   */
  nameEn?: string;
  /**
   * 姓
   */
  familyNameJp: string;
  /**
   * 姓 (EN)
   */
  familyNameEn?: string;
  /**
   * セイ
   */
  familyNameKana?: string;
  /**
   * 名
   */
  firstNameJp: string;
  /**
   * 名 (EN)
   */
  firstNameEn?: string;
  /**
   * メイ
   */
  firstNameKana?: string;
  /**
   * E メールアドレス
   */
  email?: string;
  /**
   * 電話番号
   */
  phoneNumber?: string;
  /**
   * 緊急連絡先電話番号
   */
  emergencyPhoneNumber?: string;
  /**
   * メモ
   */
  memo?: string;
  /**
   * 性別
   */
  gender?: 'Male' | 'Female' | 'Not applicable';
  /**
   * 生年月日
   */
  birthday?: string;
  /**
   * ペルソナ ID
   * bitface backend がこの `personaId` の値を使っているので、廃止する場合は事前に顔認証チームに共有お願いします。
   *
   */
  personaId?: string;
  /**
   * workhub システム上の最高権限
   */
  superUser?: boolean;
  /**
   * BKP の UserId (旧 externalId)
   */
  bkpUserId?: string;
  /**
   * BKP の UserPersonaId (defaultPersonaId)
   */
  bkpDefaultPersonaId?: string;
  from?: EpochMillis;
  to?: EpochMillis;
  /**
   * People の種類
   */
  type: 'Member' | 'Cast' | 'Visitor' | 'Customer';
  /**
   * 従業員設定
   */
  employeeSetting?: {
    /**
     * 社員番号など
     */
    employeeCode?: string;
    /**
     * 役職
     */
    employeeRole?: string;
    /**
     * 職種
     */
    occupationName?: string;
    joiningAt?: EpochMillis;
    validTerm?: {
      from?: EpochMillis;
      to?: EpochMillis;
      /**
       * 有効期限が切れる理由。現在は退職のみ
       */
      expiryReason?: 'retirement';
    };
    /**
     * 勤務地の空間 ID
     */
    workspaceId?: string;
  };
  /**
   * ロール ID
   */
  roleIds: string[];
  /**
   * ユーザグループ ID
   */
  userGroupIds: string[];
  /**
   * 権威ロール ID
   */
  authorityRoleIds?: string[];
  /**
   * アイコン画像
   */
  iconImage?: string;
  /**
   * 外部 ID 情報
   */
  externalIds?: {
    /**
     * FaceTime ID
     */
    faceTimeId?: string;
    /**
     * Slack ID
     */
    slackId?: string;
    /**
     * Office 365 ID
     */
    office365Id?: string;
    /**
     * Microsoft Teams ID
     */
    teamsId?: string;
    /**
     * Microsoft Teams チャット
     */
    teamsChat?: {
      /**
       * チャット ID
       */
      id: string;
      participantOffice365Ids: string[];
    };
    /**
     * Meta ID
     */
    workplaceFromMetaId?: string;
    /**
     * WowTalk ID
     */
    wowtalkId?: string;
    /**
     * Google Chat ID
     */
    googleChatId?: string;
    /**
     * HILLS ID
     */
    hillsId?: string;
  };
  receptionSetting?: {
    /**
     * 所属
     */
    companyName: string;
    /**
     * ゲストの到着通知のチャンネル
     */
    slackNotifyChannel?: string;
  };
  /**
   * 本人確認`
   */
  identification?: {
    /**
     * 本人確認済み
     */
    confirmed: boolean;
    /**
     * 本人確認画像
     */
    images?: string[];
  };
  /**
   * ユーザの属性
   */
  attributes?: ('Representative' | 'Contractor' | 'BillingContact' | 'BillTo' | 'TenantUser')[];
  /**
   * 担当者情報
   */
  personResponsibleData?: {
    /**
     * 部門コード
     */
    departmentCode: string;
    /**
     * 部門名
     */
    departmentName: string;
    /**
     * 担当コード
     */
    offCode: string;
    /**
     * 担当名
     */
    offCodeName: string;
    /**
     * 販売店コード
     */
    salesCode: string;
    /**
     * 販売店名
     */
    salesName: string;
  };
  /**
   * BKP アカウントを作成する (従業員以外)
   */
  createBkpAccount?: boolean;
  /**
   * 組織へ招待する (従業員以外)
   */
  inviteOrganization?: boolean;
  /**
   * 招待メールを送信する
   */
  sendInvitationEmail?: boolean;
  /**
   * People ID
   */
  id: string;
};
export type CreatePeopleBody = {
  /**
   * コード
   */
  code: string;
  /**
   * 管理コード
   * 特定の使い方はなく、任意のコードを People へ付与したい場合に利用する
   *
   */
  managedCode?: string;
  /**
   * 氏名
   */
  nameJp: string;
  /**
   * 氏名 (EN)
   */
  nameEn?: string;
  /**
   * 姓
   */
  familyNameJp: string;
  /**
   * 姓 (EN)
   */
  familyNameEn?: string;
  /**
   * セイ
   */
  familyNameKana?: string;
  /**
   * 名
   */
  firstNameJp: string;
  /**
   * 名 (EN)
   */
  firstNameEn?: string;
  /**
   * メイ
   */
  firstNameKana?: string;
  /**
   * E メールアドレス
   */
  email?: string;
  /**
   * 電話番号
   */
  phoneNumber?: string;
  /**
   * 緊急連絡先電話番号
   */
  emergencyPhoneNumber?: string;
  /**
   * メモ
   */
  memo?: string;
  /**
   * 性別
   */
  gender?: 'Male' | 'Female' | 'Not applicable';
  /**
   * 生年月日
   */
  birthday?: string;
  /**
   * ペルソナ ID
   * bitface backend がこの `personaId` の値を使っているので、廃止する場合は事前に顔認証チームに共有お願いします。
   *
   */
  personaId?: string;
  /**
   * workhub システム上の最高権限
   */
  superUser?: boolean;
  /**
   * BKP の UserId (旧 externalId)
   */
  bkpUserId?: string;
  /**
   * BKP の UserPersonaId (defaultPersonaId)
   */
  bkpDefaultPersonaId?: string;
  from?: EpochMillis;
  to?: EpochMillis;
  /**
   * People の種類
   */
  type: 'Member' | 'Cast' | 'Visitor' | 'Customer';
  /**
   * 従業員設定
   */
  employeeSetting?: {
    /**
     * 社員番号など
     */
    employeeCode?: string;
    /**
     * 役職
     */
    employeeRole?: string;
    /**
     * 職種
     */
    occupationName?: string;
    joiningAt?: EpochMillis;
    validTerm?: {
      from?: EpochMillis;
      to?: EpochMillis;
      /**
       * 有効期限が切れる理由。現在は退職のみ
       */
      expiryReason?: 'retirement';
    };
    /**
     * 勤務地の空間 ID
     */
    workspaceId?: string;
  };
  /**
   * ロール ID
   */
  roleIds: string[];
  /**
   * ユーザグループ ID
   */
  userGroupIds: string[];
  /**
   * 権威ロール ID
   */
  authorityRoleIds?: string[];
  /**
   * アイコン画像
   */
  iconImage?: string;
  /**
   * 外部 ID 情報
   */
  externalIds?: {
    /**
     * FaceTime ID
     */
    faceTimeId?: string;
    /**
     * Slack ID
     */
    slackId?: string;
    /**
     * Office 365 ID
     */
    office365Id?: string;
    /**
     * Microsoft Teams ID
     */
    teamsId?: string;
    /**
     * Microsoft Teams チャット
     */
    teamsChat?: {
      /**
       * チャット ID
       */
      id: string;
      participantOffice365Ids: string[];
    };
    /**
     * Meta ID
     */
    workplaceFromMetaId?: string;
    /**
     * WowTalk ID
     */
    wowtalkId?: string;
    /**
     * Google Chat ID
     */
    googleChatId?: string;
    /**
     * HILLS ID
     */
    hillsId?: string;
  };
  receptionSetting?: {
    /**
     * 所属
     */
    companyName: string;
    /**
     * ゲストの到着通知のチャンネル
     */
    slackNotifyChannel?: string;
  };
  /**
   * 本人確認`
   */
  identification?: {
    /**
     * 本人確認済み
     */
    confirmed: boolean;
    /**
     * 本人確認画像
     */
    images?: string[];
  };
  /**
   * ユーザの属性
   */
  attributes?: ('Representative' | 'Contractor' | 'BillingContact' | 'BillTo' | 'TenantUser')[];
  /**
   * 担当者情報
   */
  personResponsibleData?: {
    /**
     * 部門コード
     */
    departmentCode: string;
    /**
     * 部門名
     */
    departmentName: string;
    /**
     * 担当コード
     */
    offCode: string;
    /**
     * 担当名
     */
    offCodeName: string;
    /**
     * 販売店コード
     */
    salesCode: string;
    /**
     * 販売店名
     */
    salesName: string;
  };
  /**
   * BKP アカウントを作成する (従業員以外)
   */
  createBkpAccount?: boolean;
  /**
   * 組織へ招待する (従業員以外)
   */
  inviteOrganization?: boolean;
  /**
   * 招待メールを送信する
   */
  sendInvitationEmail?: boolean;
};
