import styled from '@emotion/styled';
import * as React from 'react';

import useDict from '@/common/hooks/useDict';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHColor} from '@/common/styles/whColor';

const OptionalLabel = styled.div`
  font-size: 10px;
  color: ${WHColor.text.neutralDisabled};
  margin-left: 4px;
`;
const dictDef = {
  optional: {
    default: {
      default: '任意',
      [Locale.en_US]: 'Optional',
    },
  },
};

const WOptionalLabel = React.memo(function WOptionalLabel() {
  const dict = useDict(dictDef);
  return <OptionalLabel>{dict.optional}</OptionalLabel>;
});

export default WOptionalLabel;
