import {InvitedFromType} from '@bitkey-service/workhub-types/lib/firestore/organizations/FirestoreTypesGuestOrganizations';
import {GuestOrganizationType} from '@bitkey-service/workhub-types/lib/firestore/organizations/guestOrganizations/firestoreTypesOrganizaitonsGuestOrganizaions';
import {createSlice} from '@reduxjs/toolkit';

import {GuestOrganizationState} from '../state-types/organizationStateType';

const initialState: GuestOrganizationState = {
  id: '',
  code: '',
  nameJp: '',
  nameEn: '',
  type: GuestOrganizationType.customer,
  invitedFrom: '',
  invitedFromType: InvitedFromType.organization,
};

const guestOrganizationSlice = createSlice({
  name: 'guestOrganization',
  initialState,
  reducers: {
    set: (state, action: {payload: Partial<GuestOrganizationState>}) => ({...state, ...action.payload}),
    clear: () => initialState,
  },
});

export default guestOrganizationSlice;
