import {ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {Suspense, useEffect, useState} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';

import {simpleSnackbarContainer} from '@/components/layouts/simpleSnackbarContainer';
import Router from '@/routing/Router';

import Env from './common/env/env';
import {setupStore} from './common/redux/store/store';
import {ThemeDefaults} from './common/styles/theme';
import DeveloperTools from './components/developer-tools/DeveloperTools';
import NetWorkWatcher from './components/network-watcher/NetworkWatcher';
import {Datadog} from './monitoring/datadog';

import '@/common/useDayjs';

Datadog.startMonitoring();
const queryClient = new QueryClient();
const store = setupStore();

const developerWatcherProjects = ['bitlock-dev', 'bitlock-staging', 'workhub-local'];

const App = () => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    Env.init();
    setInitialized(true);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_GA_ID}`}></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${import.meta.env.VITE_GA_ID}');`}
        </script>
      </Helmet>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider theme={ThemeDefaults}>
            <simpleSnackbarContainer.Provider>
              <Suspense fallback={null}>{initialized ? <Router /> : <div />}</Suspense>
              <NetWorkWatcher />
              {developerWatcherProjects.includes(import.meta.env['VITE_FIREBASE_PROJECT_ID'] as string) && (
                <DeveloperTools />
              )}
            </simpleSnackbarContainer.Provider>
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
