import styled from '@emotion/styled';
import {ComponentProps, memo} from 'react';

import {core, WHColor} from '@/common/styles/whColor';

const Wrapper = styled.section`
  background-color: ${WHColor.surface.neutralHigh};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BannerList = styled.ul`
  padding: 0;
  list-style: none;
  max-width: 700px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  flex-direction: column;
  margin: 0 auto;
`;

const BannerListItem = styled.li`
  list-style: none;
`;

type Props = {
  images?: {
    imgSrc: string;
    sourceSrcList: string[];
    alt?: string;
    href?: string;
  }[];
} & Omit<ComponentProps<typeof Wrapper>, 'children'>;
const AdvertiseBannerScreen = memo<Props>(function AdvertiseBannerScreen({images, ...rest}) {
  return (
    <Wrapper {...rest}>
      {images ? (
        <BannerList>
          {images.map((image, index) => {
            return (
              <BannerListItem key={`AdvertiseBannerScreen-BannerListItem-${index}`}>
                <Banner image={image} />
              </BannerListItem>
            );
          })}
        </BannerList>
      ) : null}
    </Wrapper>
  );
});

const Banner = memo<{image: Required<Props>['images'][number]}>(function AdvertiseBannerScreen__Banner({image}) {
  const {imgSrc, sourceSrcList, alt, href} = image;

  if (!href) {
    return (
      <Picture>
        {sourceSrcList.map(src => (
          <source key={src} width='700' height='250' src={src} />
        ))}
        <img width='700' height='250' src={imgSrc} alt={alt} />
      </Picture>
    );
  }
  return (
    <Link href={href} target='_blank' rel='noopener noreferrer'>
      <Picture>
        {sourceSrcList.map(src => (
          <source key={src} width='700' height='250' src={src} />
        ))}
        <img width='700' height='250' src={imgSrc} alt={alt} />
      </Picture>
    </Link>
  );
});

const Picture = styled.picture`
  display: block;
  & > img,
  & > source {
    display: block;
    width: 100%;
    height: auto;
  }
  transition-property: transform, box-shadow;
  transition-duration: 300ms, 300ms;
  transition-timing-function: ease-in-out, ease-in-out;
  box-shadow: 0 0 0 0 transparent;
  @media (hover: hover) {
    &:hover {
      transform: translateX(-5px) translateY(-2px);
      box-shadow: 8px 3px 22px 0 ${core.gray500};
    }
  }
`;
const Link = styled.a`
  transition-property: opacity, transform;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  opacity: 1;
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default AdvertiseBannerScreen;
