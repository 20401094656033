import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import * as React from 'react';

import useDict from '@/common/hooks/useDict';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHColor} from '@/common/styles/whColor';
import {WHFont} from '@/common/styles/whFont';

const dictDef = {
  optional: {
    default: {
      default: '任意',
      [Locale.en_US]: 'Optional',
    },
  },
};

const StyledChip = styled(styled(Chip)`
  color: ${WHColor.text.neutralDisabled};
  background-color: ${WHColor.surface.neutralHigh};
  border-radius: 32px;
  margin-left: 8px;

  & .MuiChip-label {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.MuiChip-root {
    height: 18px;
    margin-bottom: 1px;
  }
`)({...WHFont.labelSmallStrong});

const WOptionalChip: React.FC = () => {
  const dict = useDict(dictDef);

  return <StyledChip label={dict.optional} />;
};

export default React.memo(WOptionalChip);
