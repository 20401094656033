// generated-by-gen
import type {AxiosResponse} from 'axios';

import {getCoreAxios} from '@/common/axios/getCoreAxios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
import Logger from '@/common/logger/logger';

const logger = Logger.create('getOrgOverridedExperimentalFeaturesApi');

export const getOrgOverridedExperimentalFeaturesApi = async (opts?: {region?: 'default' | 'asia'}) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  const idToken = await getFirebaseIdToken();
  return await axios
    .get<GetOrgOverridedExperimentalFeaturesResponse>(`/organizations/experimental-features`, {
      headers: {
        authorization: `bearer ${idToken}`,
      },
    })
    .catch(axiosCatch(getStackTrace()));
};

export type GetOrgOverridedExperimentalFeaturesResponse = {
  code: 'lockUnlockLogV2' | 'reservationV3' | 'floorMapV2' | 'accessControlCriteria';
  enabled: boolean;
}[];
