import { jsx as _jsx } from "react/jsx-runtime";
import { useFocusable, useFocusRing } from '@react-aria/focus';
import { mergeProps, useObjectRef } from '@react-aria/utils';
import { forwardRef } from 'react';
import styles from './Focusable.module.css';
const _Focusable = ({ autoFocus, disabled, children }, ref) => {
    ref = useObjectRef(ref);
    const { focusableProps } = useFocusable({
        autoFocus,
        isDisabled: disabled,
    }, ref);
    const { focusProps, isFocused, isFocusVisible } = useFocusRing();
    return (_jsx("span", { ...mergeProps(focusableProps, focusProps), ref: ref, className: styles.root, "data-focused": isFocused || null, "data-focus-visible": isFocusVisible || null, tabIndex: 0, children: children }));
};
/**
 * インタラクティブでない要素でフォーカスおよびホバー操作をサポートするためのコンポーネント.
 * このパッケージで実装されたボタン等以外でも, これを使うことでツールチップが表示できるようになる.
 */
export const Focusable = forwardRef(_Focusable);
