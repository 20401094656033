import {AxiosRequestConfig} from 'axios';

import {catchStack} from '@/common/error/stacktrace';
import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import {ApiAccount} from '../apiAccount';

const basePath = '/users';

export class ApiAccountUsers {
  private static get = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiAccount.get<Response>(FirebaseFunctions.makePath(basePath, path), config).catch(catchStack());

  public static getUserPersonas = ({userId}: {userId: string}) =>
    ApiAccountUsers.get<
      {
        personaId: string;
        organizationId: string;
        organizationName: string;
      }[]
    >(`/${userId}/personas`);
}
