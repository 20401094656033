// generated-by-gen
import type {AxiosResponse} from 'axios';

import {getCoreAxios} from '@/common/axios/getCoreAxios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
import Logger from '@/common/logger/logger';

const logger = Logger.create('listOrganizationAnnouncementsApi');

export const listOrganizationAnnouncementsApi = async (opts?: {region?: 'default' | 'asia'}) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  const idToken = await getFirebaseIdToken();
  return await axios
    .get<ListOrganizationAnnouncementsResponse>(`/organizations/announcements`, {
      headers: {
        authorization: `bearer ${idToken}`,
      },
    })
    .catch(axiosCatch(getStackTrace()));
};

export type ListOrganizationAnnouncementsResponse = {
  id: string;
  announcementType: 'release_note' | 'notification';
  title: string;
  description: string;
  content: string;
  attachedImageUrlList: string[];
  clientType: 'app' | 'saas';
  status: 'unpublished' | 'published' | 'closed';
  businessTypes: (
    | 'CORE'
    | 'CO_WORKING'
    | 'RESIDENCE'
    | 'RENTAL_MANAGEMENT'
    | 'office'
    | 'coworking'
    | 'coworking-guest'
  )[];
  publishTargets?: string[];
  publishedAt?: number;
  publishScheduledAt?: number;
  closeScheduledAt?: number;
  showOrgAttributes?: ('Sumamoru' | 'UseViewingReservationRequestSite' | 'UseBoardMeetingMenu')[];
  hideOrgAttributes?: ('Sumamoru' | 'UseViewingReservationRequestSite' | 'UseBoardMeetingMenu')[];
  read: boolean;
  readAt?: number;
}[];
