import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, {CSSProperties} from 'react';

import {WHColor} from '@/common/styles/whColor';

const LoadingComponent = styled.div<{
  notTransparent?: boolean;
  position?: CSSProperties['position'];
  top?: number;
  background?: string;
}>`
  left: 0;
  right: 0;
  top: ${props => (props.top ? props.top + 'px' : '0')};
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: ${props => (props.position ? props.position : 'fixed')};
  background: ${props =>
    !props.notTransparent
      ? WHColor.background.overlay
      : props.notTransparent && props.background
        ? props.background
        : undefined};
  z-index: 20;
`;

const Message = styled(Typography)<{notTransparent?: boolean}>`
  margin-top: var(--spacing-16);
  text-align: center;
  opacity: 1;
  font-size: 16px;
  color: ${({notTransparent}) => (notTransparent ? WHColor.text.neutralPrimary : WHColor.text.neutralWhitePrimary)};
`;

const WLoadingComponent: React.FC<{
  message: string;
  notTransparent?: boolean;
  position?: CSSProperties['position'];
  top?: number;
  background?: string;
}> = ({message, notTransparent, position, top, background}) => (
  <LoadingComponent notTransparent={notTransparent} position={position} top={top} background={background}>
    <CircularProgress />
    <Message notTransparent={notTransparent}>{message}</Message>
  </LoadingComponent>
);

export default React.memo(WLoadingComponent);
