import {UserGroupType} from '@bitkey-service/v2_core-types/lib/store/organizations/user-groups/v2_storeTypesOrgUserGroup';
import {AuthorityLevel} from '@bitkey-service/workhub-types/lib/firestore/organizations/authority-pattern/firestoreTypesOrganizationsAuthorityPattern';
import {InvitedFromType} from '@bitkey-service/workhub-types/lib/firestore/organizations/FirestoreTypesGuestOrganizations';
import styled from '@emotion/styled';
import * as Icon from '@workhub/icons';
import {IconButton} from '@workhub/ui';
import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import useDict, {useAuthDict, useCommonDict} from '@/common/hooks/useDict';
import {useLocale} from '@/common/hooks/useLocale';
import {shadowColor} from '@/common/styles/whColor';
import {WHFontCss} from '@/common/styles/whFont';
import {addPreloadErrorListener, removePreloadErrorListener} from '@/common/vite/preloadErrorListener';
import WButton from '@/components/button/WButton';
import WDivider from '@/components/divider/WDivider';
import WFormDialogTextField from '@/components/figma/others/dialog/form/WFormDialogTextField';
import WLabelContent from '@/components/figma/others/label/WLabelContent';
import {Datadog} from '@/monitoring/datadog';
import {initExperimentalFeatures} from '@/services/feature-toggle/experimentalFeatureService';
import {useValidatePassword} from '@/wcustomhooks/auth/useValidatePassword';

import {ApiAccountAuth} from '../../api/account/auth/apiAccountAuth';
import {ApiAccountUsers} from '../../api/account/users/apiAccountUsers';
import {FeatureControl} from '../../common/feature-control/featureControl';
import {Feature} from '../../common/feature-control/featureDefinitions';
import {Firebase} from '../../common/firebase/firebase';
import FirebaseAuth from '../../common/firebase/firebase-auth';
import {FirebaseFunctions} from '../../common/firebase/functions/firebase-functions';
import Logger from '../../common/logger/logger';
import announcementSlice from '../../common/redux/slices/announcementSlice';
import businessTypeSlice from '../../common/redux/slices/businessTypeSlice';
import guestOrganizationSlice from '../../common/redux/slices/guestOrganizationSlice';
import localeSlice from '../../common/redux/slices/localeSlice';
import organizationSlice from '../../common/redux/slices/organizationSlice';
import userGroupSlice from '../../common/redux/slices/userGroupSlice';
import userSlice from '../../common/redux/slices/userSlice';
import {Locale} from '../../common/redux/state-types/localeStateType';
import LocalStorage, {LocalStorageKey, LoginPersonaLocalStorage} from '../../common/storage/localStorage';
import {KeyCode} from '../../common/utils/keyCode';
import WorkhubLogoIcon from '../../components/icon/WorkhubLogoIcon';
import {AuthService} from '../../services/auth/authService';
import {NotificationService} from '../../services/notification/notification-service';
import {V2PeopleService} from '../../v2_service/people/V2_peopleService';

interface P {
  transitionResetPasswordScreen: () => void;
  transitionLoginWithExternalIDScreen: () => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
}

const LoginFormArea = styled.div`
  width: 385px;
  height: 680px;
  padding: var(--spacing-16);
  box-sizing: border-box;
  box-shadow: 0 4px 10px ${shadowColor.shadowAlpha020};
  border-radius: var(--radius-l);
  background-color: var(--surface-neutral-low);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleArea = styled.div`
  height: 220px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const InputArea = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMessageArea = styled.div`
  color: var(--text-semantic-error);
  font-size: 12px;
  margin-top: var(--spacing-8);
`;

const InputPassWordWrapper = styled.div`
  position: relative;
`;

const VisibilityIconButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
`;

const ForgetPasswordWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: var(--spacing-12);
  margin-bottom: var(--spacing-12);
`;

// うめきたPJ関連システムのリリースが未定のため、「外部IDでログイン」ボタンを本番環境では表示しないようにしている。
// そのため、「ログイン」ボタンの位置を調整。リリース日程が決まり次第、適切なmarginに戻す。
const LoginButtonArea = styled.div`
  margin-top: ${import.meta.env.VITE_ENVIRONMENT === 'production' ? '90px' : '40px'};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ForgotPasswordTextWrapper = styled.span`
  color: var(--text-brand-primary);
  font-size: 12px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const DividerWrapper = styled.div`
  ${WHFontCss.labelLarge}
  width: 100%;
  padding: var(--spacing-24) 0;
`;

const ResetPasswordText = styled.a`
  color: var(--text-semantic-error);
  ${WHFontCss.labelSmall}
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: var(--spacing-8);
`;
const ResetPasswordTextWrapper = styled.div`
  display: flex;
`;

const dictDef = {
  login: {
    default: {
      default: 'ログイン',
      [Locale.en_US]: 'Login.',
    },
  },
  loginWithExternalId: {
    default: {
      default: '外部IDでログイン',
      [Locale.en_US]: 'Login with external id.',
    },
  },
  emailAddress: {
    default: {
      default: 'メールアドレス',
      [Locale.en_US]: 'Email address',
    },
  },
  password: {
    default: {
      default: 'パスワード',
      [Locale.en_US]: 'Password',
    },
  },
  togglePasswordVisibility: {
    default: {
      default: 'パスワードの表示/非表示',
      [Locale.en_US]: 'Toggle password visibility',
    },
  },
  forgetPassword: {
    default: {
      default: 'パスワードを忘れた場合はこちら',
      [Locale.en_US]: 'Forgot your password?',
    },
  },
  passwordRecoveryLink: {
    default: {
      default: 'パスワードの再設定',
      [Locale.en_US]: 'Reset your password',
    },
  },
  actionPrompt: {
    default: {
      default: 'を行なってください',
      [Locale.en_US]: 'Please',
    },
  },
};

const logger = Logger.create('LoginScreen');
const LoginScreen = React.memo(function LoginScreen(props: P) {
  const navigate = useNavigate();
  const dict = useDict(dictDef);
  const authDict = useAuthDict();
  const commonDict = useCommonDict();
  const locale = useLocale();
  const {transitionResetPasswordScreen, transitionLoginWithExternalIDScreen, email, setEmail, password, setPassword} =
    props;
  const onChangeEmail = useCallback(event => setEmail(event.target.value), [setEmail]);
  const onChangePassword = useCallback(event => setPassword(event.target.value), [setPassword]);
  const lastLoginPersona = useMemo<{personaId: string | undefined} | undefined>(() => {
    return LoginPersonaLocalStorage.get();
  }, []);
  const dispatch = useDispatch();

  const [running, setRunning] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => {
    setShowPassword(value => !value);
  }, []);
  const authErrorMessage = useMemo(() => {
    if (!isError) return;
    return authDict.loginErrorMessage(errorCode, isLocked);
  }, [authDict, errorCode, isError, isLocked]);

  const onClickLogin = useCallback(async () => {
    setIsError(false);
    setIsLocked(false);
    // ログイン成功して認証情報をセットする前にreload走ると再度ログイン画面に戻るので、reloadイベントを削除
    removePreloadErrorListener();
    setRunning(true);
    const selectedLanguage = LocalStorage.get(LocalStorageKey.SelectedLanguage);
    if (selectedLanguage) {
      dispatch(localeSlice.actions.set(selectedLanguage as Locale));
    }
    const authService = AuthService.getInstance();
    try {
      const isEmailSameAsLastLoginEmail = await LoginPersonaLocalStorage.isEmailSameAsLastLoginEmail(email);
      const loginPersona = await ApiAccountAuth.loginWithEmailAndAuthority({
        email,
        password,
        personaId: isEmailSameAsLastLoginEmail ? lastLoginPersona?.personaId : undefined,
      });

      const {
        organization,
        activations,
        tokens: {accessToken, refreshToken, customToken},
        userGroups,
        people,
        authority,
        userGroupRights,
      } = loginPersona;

      // TODO: バックエンドの型がrequiredになったら消す
      if (!people) {
        throw new Error('Unexpected no people');
      }
      // ログインにはemailとbkp必要
      if (!(people.email && people.personaId)) {
        throw new Error('invalid user');
      }

      Firebase.getInstance();
      await FirebaseAuth.createInstance().signIn(customToken);
      await initExperimentalFeatures(dispatch);

      Datadog.setContext(people, userGroupRights, authority, organization);

      authService.authSettingCommon(
        {
          businessType: organization.businessType,
          roles: loginPersona.roles.map(role => ({
            ...role,
            from: role.from.toString(),
            to: role.to.toString(),
          })),
        },
        logger,
        dispatch
      );

      // Feature.AboutBitkey(外部リンク系)は全員に表示したいのでここで付与
      authority[Feature.AboutBitkey] = AuthorityLevel.Write;

      const userGroupTypes: UserGroupType[] = userGroups.map(group => group.type);
      const superUser = !!people?.superUser;

      //thirdPlace会員の場合、権限を変更する処理
      const convertedAuthority =
        people?.type === 'Customer' && userGroupTypes.includes(UserGroupType.Tenant)
          ? FeatureControl.createInstance(superUser).convertAuthorityLevelForThirdPlaceCustomer(authority)
          : // マイグレーション後にauthorityを参照する
            // ref: https://bit-key.atlassian.net/browse/WWA-14858
            // ref: https://bit-key.atlassian.net/browse/WWA-14859
            // : authority;
            FeatureControl.createInstance(superUser).convertAuthorityLevel(authority);

      dispatch(
        userSlice.actions.set({
          organizationId: organization.id,
          organization,
          personaId: people.personaId,
          accessToken,
          refreshToken,
          code: people.code,
          email: people.email,
          switchablePersonas: [],
          familyNameEn: people.familyNameEn,
          familyNameJp: people.familyNameJp,
          familyNameKana: people.familyNameKana,
          firstNameEn: people.firstNameEn,
          firstNameJp: people.firstNameJp,
          firstNameKana: people.firstNameKana,
          nameJp: people.nameJp,
          nameEn: people.nameEn,
          // photoUri: member.photoUri ? member.photoUri : '',
          // themeColor: member.themeColor ? member.themeColor : '',
          activations,
          authority: convertedAuthority,
          userGroupIds: people.userGroupIds || [],
          userGroups,
          peopleId: people?.id,
          peopleType: people?.type,
          people,
          superUser: people?.id ? superUser : undefined,
          debuggable: loginPersona.debuggable,
          experimentable: loginPersona.experimentable,
        })
      );
      dispatch(
        organizationSlice.actions.set({
          id: organization.id,
          code: organization.code,
          nameJp: organization.name,
          nameEn: organization.nameEn,
          ownerOrganizationIds: organization.ownerOrganizationIds,
          requireSpaceFiltering: organization.requireSpaceFiltering,
          tempAccessControlVersionSettings: organization.tempAccessControlVersionSettings,
          reservationCalendarVersionSettings: organization.reservationCalendarVersionSettings,
        })
      );
      dispatch(userGroupSlice.actions.set({userGroups}));

      FirebaseFunctions.setAccessToken(accessToken);
      if (people.bkpUserId) {
        // await 禁止
        ApiAccountUsers.getUserPersonas({userId: people.bkpUserId}).then(personas => {
          dispatch(
            userSlice.actions.set({
              switchablePersonas: personas
                .filter(p => p.organizationId !== organization.id)
                .map(p => ({
                  id: p.personaId,
                  organizationId: p.organizationId,
                  organizationName: p.organizationName,
                })),
            })
          );
        });
      }
      dispatch(businessTypeSlice.actions.set(organization.businessType));
      if (organization.invitedFrom && organization.invitedFromType) {
        dispatch(
          guestOrganizationSlice.actions.set({
            id: organization.id,
            code: organization.code,
            nameJp: organization.name,
            nameEn: organization.nameEn,
            invitedFrom: organization.invitedFrom,
            invitedFromType: organization.invitedFromType as InvitedFromType,
          })
        );
      }

      NotificationService.loadAnnouncements().then(announcements => {
        dispatch(announcementSlice.actions.set(announcements));
      });

      LoginPersonaLocalStorage.setOnLogin({
        organizationId: organization.id,
        email: people.email,
        personaId: people.personaId,
        refreshToken: refreshToken,
        accessToken: accessToken,
      });

      // authorityはそのまま持たせたいが、bitlockAppとbitReception, WorkhubApp関連の権限は管理画面と無関係なので除外する
      const ignoreFeatures: Feature[] = [
        Feature.V2BitlockApp,
        Feature.V2ReceptionApp,
        Feature.V2WorkhubAppOrganization,
        Feature.WorkhubAppPersonalizedNfcCard,
        Feature.AzbilCloudOperation,
        Feature.TabletOperation,
        //上で全員に付与しているため下記も加えないとリダイレクトされないため追加
        Feature.AboutBitkey,
      ];
      const levels = Object.keys(authority).map(key =>
        ignoreFeatures.includes(key as Feature) ? AuthorityLevel.None : authority[key as Feature]
      );

      // 権限が全てnoneであれば利用できるメニューがない旨を通知する画面にリダイレクトする
      if (!superUser && levels.length > 0 && levels.every(l => l === AuthorityLevel.None)) {
        navigate('/');
      }
      V2PeopleService.getSpaceAuthority(people?.id)
        .then(authority => {
          dispatch(
            userSlice.actions.set({
              visibleSpaces: authority.visibleSpaces,
            })
          );
        })
        .catch(e => {
          logger.error('failed to get person space authority', e);
          dispatch(
            userSlice.actions.set({
              visibleSpaces: [], //エラーだったら権限取れないので安全のため何も見せない
            })
          );
        });
    } catch (e) {
      setIsError(true);
      const errorCode = e?.response?.data?.code;
      const errorMessage = e?.response?.data?.message;
      // バックエンド側でAxiosの汎用的な処理としてまるごとresponse.massageに文字列を入れているので、それを見てロックアウトかどうかを判定する
      setIsLocked(errorMessage?.includes('lockout:true'));
      setErrorCode(errorCode);
      LoginPersonaLocalStorage.clear(); // ログインに必ず失敗する最終ログイン組織Persona情報残しちゃうと、ログイン失敗し続けて管理画面にログインできず、別の所属組織へのスイッチ動線が閉じてしまう。なので、ログイン失敗したら最終ログインPersona情報消しておく。
      Datadog.clearContext();
    } finally {
      setRunning(false);
      // ログイン成功して認証情報をセットできてもできなくてもpreloadErrorのlistenerは登録しておきたいので追加
      addPreloadErrorListener();
    }
  }, [dispatch, email, password, lastLoginPersona?.personaId, navigate]);

  const onEnter = useCallback(
    e => {
      if (running || !email || !password) {
        return;
      }
      if (e.keyCode === KeyCode.Enter) {
        onClickLogin().catch(() => {});
      }
    },
    [email, onClickLogin, password, running]
  );

  const {passwordErrorMessage} = useValidatePassword(password);

  // TODO lastLoginPersonaIdがある場合（自分でログアウトはしていなくて、refresh失敗した場合）はemailいらない
  return (
    <LoginFormArea>
      <TitleArea>
        <WorkhubLogoIcon size={120} />
      </TitleArea>
      <InputArea>
        <WLabelContent
          label={dict.emailAddress}
          content={
            <WFormDialogTextField
              id={'email'}
              name={commonDict.emailAddress}
              type={'email'}
              value={email}
              onChange={onChangeEmail}
              role={'textbox'}
            />
          }
          width={'100%'}
        />
        <WLabelContent
          label={dict.password}
          content={
            <>
              <InputPassWordWrapper>
                <WFormDialogTextField
                  id={'password'}
                  name={dict.password}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={onChangePassword}
                  onKeyDown={onEnter}
                  role={'textbox'}
                />
                <VisibilityIconButtonWrapper>
                  <IconButton title={dict.togglePasswordVisibility} onClick={toggleShowPassword}>
                    {showPassword ? <Icon.Visibility /> : <Icon.VisibilityOff />}
                  </IconButton>
                </VisibilityIconButtonWrapper>
              </InputPassWordWrapper>
              <ForgetPasswordWrapper>
                <ForgotPasswordTextWrapper onClick={transitionResetPasswordScreen}>
                  {dict.forgetPassword}
                </ForgotPasswordTextWrapper>
              </ForgetPasswordWrapper>
            </>
          }
          width={'100%'}
          marginTop={'var(--spacing-16)'}
        />
        <LoginButtonArea>
          <WButton
            label={dict.login}
            variant={'contained'}
            color={'primary'}
            onClick={onClickLogin}
            disabled={running || !email || !password || !!passwordErrorMessage}
            width={'100%'}
            height={40}
            borderRadius={4}
            fontSize={14}
            running={running}
            id='login-button'
          />
          {authErrorMessage && <ErrorMessageArea>{authErrorMessage}&nbsp;</ErrorMessageArea>}
          {isLocked && (
            <ResetPasswordTextWrapper>
              {/* 英文字の時だけ半角スペースをつけたいので &nbsp;をつける */}
              {locale !== 'ja_JP' && <ErrorMessageArea as='span'>{dict.actionPrompt} &nbsp; </ErrorMessageArea>}
              <ResetPasswordText onClick={transitionResetPasswordScreen}>{dict.passwordRecoveryLink}</ResetPasswordText>
              {locale === 'ja_JP' && <ErrorMessageArea as='span'>{dict.actionPrompt}</ErrorMessageArea>}
            </ResetPasswordTextWrapper>
          )}
          {password && !!passwordErrorMessage && <ErrorMessageArea>{passwordErrorMessage}</ErrorMessageArea>}
          <>
            <DividerWrapper>
              <WDivider>or</WDivider>
            </DividerWrapper>
            <WButton
              label={dict.loginWithExternalId}
              variant={'contained'}
              color={'secondary'}
              onClick={transitionLoginWithExternalIDScreen}
              disabled={running}
              width={'100%'}
              height={40}
              borderRadius={4}
              fontSize={14}
            />
          </>
        </LoginButtonArea>
      </InputArea>
    </LoginFormArea>
  );
});

export default LoginScreen;
