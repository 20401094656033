import {type FC, memo} from 'react';
import {isMobileOnly} from 'react-device-detect';
import {createBrowserRouter, type RouteObject, RouterProvider, useRouteError} from 'react-router-dom';

import {routeObjects} from '@/wscreens/routing/routing';

import {Routing} from './Routing';

const mobileOnlyRoutes: RouteObject[] = [
  {
    path: '/reservation',
    lazy: () => import('@/mobile/screens/reservation/ReservationFromQr'),
  },
  {
    path: '/form/satisfaction/complete',
    lazy: () => import('@/mobile/screens/form/satisfaction/FormSatisfactionMobileCompleteScreen'),
  },
  {
    path: '/form/satisfaction/:satisfactionId',
    lazy: () => import('@/mobile/screens/form/satisfaction/FormSatisfactionMobileScreen'),
  },
  {
    path: '*',
    lazy: () => import('@/mobile/screens/no-page/NoPage'),
  },
];

/**
 * React Router の内側でエラーが起きた時に再スローすることで React Router がデフォルトで用意しているエラー画面を表示しないようにする
 * またエラーを外側にスローすることで何かしら対処が行われることを期待する.
 * 例えばモジュールロード関連のエラーは Vite が捕捉して vite:preloadError イベントを発行する仕組みがあるため, そちらを利用する.
 *
 * FIXME: 一方でユーザにとっては真っ白な画面が表示されることがあるので, 復帰不能なエラーが発生したときのエラー画面を用意しておいたほうが良い
 */
const ErrorBoundary: FC = (): never => {
  throw useRouteError();
};

const routes = isMobileOnly ? mobileOnlyRoutes : routeObjects;
const router = createBrowserRouter(
  [
    {
      element: <Routing />,
      errorElement: <ErrorBoundary />,
      children: routes,
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

const Router: FC = () => {
  return <RouterProvider router={router} future={{v7_startTransition: true}} />;
};

export default memo(Router);
