import { jsx as _jsx } from "react/jsx-runtime";
import * as Icon from '@bitkey-service/workhub-icons';
import { memo } from 'react';
import styles from './Loading.module.css';
const _Loading = ({ centered }) => {
    const inner = (_jsx("span", { className: styles.root, children: _jsx(Icon.Loading, {}) }));
    if (centered) {
        return _jsx("div", { className: styles.centered, children: inner });
    }
    return inner;
};
export const Loading = memo(_Loading);
