import styled from '@emotion/styled';
import BuildIcon from '@mui/icons-material/Build';
import NavigationIcon from '@mui/icons-material/Navigation';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import {useCallback, useEffect, useMemo, useState} from 'react';
import Draggable from 'react-draggable';

import LocalStorage, {LocalStorageKey} from '@/common/storage/localStorage';
import {WHColor} from '@/common/styles/whColor';

import {ExperimentalFeatureWatcher} from './ExperimentalFeatureWatcher';
import {useLoginUser} from '../../common/hooks/useLoginUser';
import WHeaderTab2 from '../../components/figma/header/WHeaderTab2';
import WInformationPopper from '../../components/figma/others/popper/WInformationPopper';
import Debugger from '../debugger/Debugger';
import ExperimentalFeatures from '../experimental-features/ExperimentalFeatures';

const Root = styled.div`
  position: fixed;
  right: 40px;
  bottom: 16px;
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${WHColor.text.neutralWhitePrimary};
  font-weight: bold;
  font-size: 18px;
  cursor: grab;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: 'center';
  justify-content: 'space-between';
`;

const ContentsRoot = styled.div`
  height: 420px;
`;

const ExperimentalFeatureWatcherWrapper = styled.div`
  position: fixed;
  right: 44px;
`;

const DeveloperToolsIcon = styled(BuildIcon)`
  position: fixed;
  right: 5px;
  bottom: 35px;
  border-radius: 100%;
  background-color: ${WHColor.object.brandPrimary};
  color: ${WHColor.text.neutralWhitePrimary};
  padding: 5px;
  font-size: 15;
`;

/**
 * 開発者メニュー
 */
function DeveloperTools() {
  const user = useLoginUser();
  const email = useMemo(() => user.email, [user.email]);
  const [debuggerEnabled, setDebuggerEnabled] = useState<boolean>(true);
  const [devToolsVisibile, setDevToolsVisible] = useState<boolean>(
    LocalStorage.get(LocalStorageKey.DevToolsVisibility) === 'true' ? true : false
  );

  const hideDebugger = useCallback(() => {
    setDebuggerEnabled(false);
  }, []);

  const minimizeDebugger = useCallback(() => {
    setDevToolsVisible(false);
    LocalStorage.set(LocalStorageKey.DevToolsVisibility, 'false');
  }, []);

  const isDisplay = useMemo(() => {
    const isApplicationMobileScreen = window.location.pathname.indexOf('/application-draft') !== -1;
    return devToolsVisibile && !isApplicationMobileScreen;
  }, [devToolsVisibile]);

  const isDeveloper: boolean = useMemo(() => {
    return !!user.debuggable || !!user.experimentable;
  }, [user]);

  const logined = !!email;

  const headerTabs = useMemo(() => {
    const tabs: {id: string; label: string}[] = [];
    if (user.debuggable) {
      tabs.push({id: 'debugger', label: '開発情報'});
    }
    if (user.experimentable) {
      tabs.push({id: 'experimentalFeatures', label: 'Features'});
    }
    return tabs;
  }, [user]);

  // headerタブは初期に決定できない
  const [headerTabId, setHeaderTabId] = useState<string>('');
  useEffect(() => {
    if (headerTabs.length > 0) {
      setHeaderTabId(headerTabs[0].id);
    }
  }, [headerTabs]);

  return (
    <>
      {debuggerEnabled && (
        <>
          {isDisplay && (
            <Draggable enableUserSelectHack={false} cancel='.developer-tools-content-root'>
              <Root>
                {isDeveloper && (
                  <WInformationPopper
                    contents={
                      <ContentsRoot className='developer-tools-content-root'>
                        {headerTabId === 'debugger' && <Debugger />}
                        {headerTabId === 'experimentalFeatures' && <ExperimentalFeatures />}
                      </ContentsRoot>
                    }
                    header={
                      <Header>
                        {headerTabs && <WHeaderTab2 tabs={headerTabs} tabId={headerTabId} onChange={setHeaderTabId} />}
                        <Button onClick={hideDebugger}>非表示</Button>
                        <Button onClick={minimizeDebugger}>縮小</Button>
                      </Header>
                    }
                    firstDisplayIcon={
                      <Fab variant='extended' style={{backgroundColor: WHColor.object.brandPrimary}}>
                        <NavigationIcon style={{color: WHColor.text.neutralWhitePrimary, marginRight: '16px'}} />
                        <span style={{color: WHColor.text.neutralWhitePrimary}}>開発用</span>
                      </Fab>
                    }
                  />
                )}
                {logined && (
                  <>
                    <ExperimentalFeatureWatcherWrapper>
                      <ExperimentalFeatureWatcher />
                    </ExperimentalFeatureWatcherWrapper>
                  </>
                )}
              </Root>
            </Draggable>
          )}
          {!devToolsVisibile && (
            <Draggable>
              <DeveloperToolsIcon
                onClick={() => {
                  setDevToolsVisible(true);
                  LocalStorage.set(LocalStorageKey.DevToolsVisibility, 'true');
                }}
              />
            </Draggable>
          )}
        </>
      )}
    </>
  );
}

export default DeveloperTools;
