import {AxiosRequestConfig} from 'axios';

import {catchStack} from '@/common/error/stacktrace';
import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import {ApiWorkhubCore} from '../../apiWorkhubCore';

export type StartLoginFlowReq = {
  email: string;
  redirectUri?: string;
};
export type StartSignupFlowReq = {
  invitationId: string;
  redirectUri?: string;
};
export type StartFlowRes = {
  codeChallenge: string;
  codeChallengeMethod: string;
  state: string;
  ssoIdProvider: string;
  peopleId: string;
  loginUrl?: string;
};

export type StartLoginFlowWithOrgCodeReq = {
  code: string;
  redirectUri?: string;
};

const basePath = () => {
  return '/oidc/sso';
};
export class ApiWorkhubCoreOidcSso {
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.post<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config).catch(
      catchStack()
    );
  private static get = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.get<Response>(FirebaseFunctions.makePath(basePath(), path), config).catch(catchStack());

  public static startLoginFlow = (data: StartLoginFlowReq) =>
    ApiWorkhubCoreOidcSso.post<StartFlowRes, StartLoginFlowReq>('/', data);

  public static startSignupFlow = (data: StartSignupFlowReq) =>
    ApiWorkhubCoreOidcSso.get<StartFlowRes>(`/?invitationId=${data.invitationId}&redirectUri=${data.redirectUri}`);

  public static startLoginFlowWithOrgCode = (data: StartLoginFlowWithOrgCodeReq) =>
    ApiWorkhubCoreOidcSso.post<Omit<StartFlowRes, 'peopleId'>, StartLoginFlowWithOrgCodeReq>(
      '/start-login-flow-with-org-code',
      data
    );
}
