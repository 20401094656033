import {V2PeopleTypes} from '@bitkey-service/v2_core-types/lib/common/v2_commonTypes';
import {
  CollectionOrgPeople,
  V2StoreTypesOrgPeople,
} from '@bitkey-service/v2_core-types/lib/store/organizations/people/v2_storeTypesOrgPeople';
import dayjs from 'dayjs';
import {limit as limitFs, orderBy, query, QueryConstraint, where, startAfter as startAfterFs} from 'firebase/firestore';

import ArrayUtil from '@/common/utils/arrayUtil';

import {Firestore} from '../firebase-firestore';

const ref = (organizationId: string) => Firestore.getInstance().getCollectionRef(CollectionOrgPeople(organizationId));

/**
 * 結局こうなるよね
 */
export class FirestoreOrgPeople {
  public static ref = (organizationId: string) => ref(organizationId);

  /**
   * 全てのpeopleを取得できるが、有効期限切れな従業員も取得してしまうので、要注意
   *
   * ユースケース：ログなど、全部のpeopleが必要な画面。
   * @static
   * @param {string} organizationId
   * @memberof FirestoreOrgPeople
   */
  public static getAll = (organizationId: string) => Firestore.getInstance().getByQuery(ref(organizationId));
  public static getById = (organizationId: string, peopleId: string) =>
    Firestore.getInstance().getById(ref(organizationId), peopleId);
  public static getByIds = (
    organizationId: string,
    peopleIds: (string | undefined)[]
  ): Promise<V2StoreTypesOrgPeople[]> => Firestore.getInstance().getByIds(ref(organizationId), peopleIds);
  public static getByPersonaId = async (organizationId: string, personaId: string): Promise<V2StoreTypesOrgPeople> => {
    return (
      await Firestore.getInstance().getByQuery(query(ref(organizationId), where('personaId', '==', personaId)))
    )[0];
  };
  public static getByCode = async (organizationId: string, code: string) => {
    return (await Firestore.getInstance().getByQuery(query(ref(organizationId), where('code', '==', code))))[0];
  };
  public static getByPersonaIds = async (organizationId: string, personaIds: string[]) => {
    return await Firestore.getByFieldValues(ref(organizationId), 'personaId', personaIds);
  };
  public static getByEmails = async (organizationId: string, emails: (string | undefined)[]) => {
    return await Firestore.getByFieldValues(ref(organizationId), 'email', emails);
  };
  public static getByUserGroupIds = async (organizationId: string, userGroupIds: (string | undefined)[]) => {
    return await Firestore.getByFieldValues(ref(organizationId), 'userGroupIds', userGroupIds, true);
  };
  public static getByCodes = async (organizationId: string, codes: (string | undefined)[]) => {
    return await Firestore.getByFieldValues(ref(organizationId), 'code', codes, false);
  };
  public static getByTypes = async (organizationId: string, types: V2PeopleTypes[]) => {
    return await Firestore.getByFieldValues(ref(organizationId), 'type', types, false);
  };
  public static getAllSuperUsers = async (organizationId: string) => {
    return await Firestore.getInstance().getByQuery(query(ref(organizationId), where('superUser', '==', true)));
  };

  public static getByPersonaOrIds = async (orgId: string, ids: string[]): Promise<V2StoreTypesOrgPeople[]> => {
    if (!ids?.length) {
      return [];
    }
    const [peopleById, peopleByPersona] = await Promise.all([
      FirestoreOrgPeople.getByIds(orgId, ids),
      FirestoreOrgPeople.getByPersonaIds(orgId, ids),
    ]);

    return ArrayUtil.removeDuplicateById([...peopleById, ...peopleByPersona]);
  };

  public static getAllWorkspaceIdExists = async (organizationId: string) => {
    return await Firestore.getInstance().getByQuery(query(ref(organizationId), orderBy('employeeSetting.workspaceId')));
  };

  /**
   * peopleを全取得する。
   * ただし、有効な従業員のみ取得する
   *
   * ユースケース：予約で参加者を選択する画面など、有効なpeopleだけを表示したい画面
   * @static
   * @param {string} organizationId
   * @memberof FirestoreOrgPeople
   */
  public static getAllActiveMember = async (organizationId: string) => {
    const today = dayjs();
    const people = (await Firestore.getInstance().getByQuery(ref(organizationId))).filter(v => {
      const validTermTo = v.employeeSetting?.validTerm?.to;
      // 有効期限切れのユーザーは弾く
      if (validTermTo && today.isAfter(validTermTo, 'day')) {
        return false;
      }
      return true;
    });

    return people;
  };

  /**
   * CSV一括取込が失敗したユーザーを取得する処理
   * @param organizationId
   */
  public static getLoadFailedPeople = async (organizationId: string) => {
    return await Firestore.getInstance().getByQuery(
      query(ref(organizationId), where('loadCondition.loadFailed', '==', true), limitFs(1))
    );
  };
  public static getByExternalId = async ({
    organizationId,
    externalName,
    id,
    limit,
  }: {
    organizationId: string;
    externalName: Extract<
      keyof Required<V2StoreTypesOrgPeople>['externalIds'],
      'faceTimeId' | 'slackId' | 'teamsId' | 'workplaceFromMetaId' | 'office365Id' | 'wowtalkId'
    >;
    id: string;
    limit?: number;
  }) => {
    const constraint = [where(`externalIds.${externalName}`, '==', id)] as QueryConstraint[];

    if (limit) constraint.push(limitFs(limit));

    return Firestore.getInstance().getByQuery(query(ref(organizationId), ...constraint));
  };
  public static getByAllUserLimit = async (organizationId: string, limit: number, startAfter: number) => {
    return await Firestore.getInstance().getByQuery(
      query(ref(organizationId), orderBy('code', 'asc'), startAfterFs(startAfter), limitFs(limit))
    );
  };
}
