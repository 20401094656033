// generated-by-gen
import type {AxiosResponse} from 'axios';

import {getCoreAxios} from '@/common/axios/getCoreAxios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import {getFirebaseIdToken} from '@/common/firebase/firebase-auth';
import Logger from '@/common/logger/logger';

const logger = Logger.create('readAnnouncementApi');

export const readAnnouncementApi = async (
  arg: {paths: ReadAnnouncementPathParam},
  opts?: {region?: 'default' | 'asia'}
) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  const idToken = await getFirebaseIdToken();
  return await axios
    .post<void>(`/organizations/announcements/${arg.paths.announcementId}/read`, undefined, {
      headers: {
        authorization: `bearer ${idToken}`,
      },
    })
    .catch(axiosCatch(getStackTrace()));
};

export type ReadAnnouncementPathParam = {
  announcementId: string;
};
