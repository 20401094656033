import {ApiTypesAddActivationsPostRequest} from '@bitkey-service/v2_core-types/lib/api/organization/activations/apiTypesActivations';
import {ActivationGroup as WActivationGroup} from '@bitkey-service/v2_core-types/lib/store/organizations/activations/v2_storeTypesOrgActivations';
import dayjs from 'dayjs';

import {
  ActivationFeatureGroupMap,
  ActivationGroup,
  Feature,
  FeatureGroup,
} from '@/common/feature-control/featureDefinitions';
import {authorityDictDef} from '@/services/authority/authorityService';
import {ActivationService} from '@/v2_service/activations/activationService';
import {MainMenuDictDef, type MainMenuDictKey} from '@/wscreens/routing/def/MainMenuDictDef';

export const authorityDict = (feature: Feature | undefined) => {
  if (!feature) {
    return undefined;
  }
  return authorityDictDef[feature]?.name?.default;
};

export const menuDict = (key: MainMenuDictKey | ''): string | undefined => {
  if (key === '') {
    return undefined;
  }

  return MainMenuDictDef[key]?.default?.default;
};

// 資料とかでは全く使わないもの(基本v1)
export const activationGroupBlackList: ActivationGroup[] = [
  ActivationGroup.Common,
  ActivationGroup.Office,
  ActivationGroup.Bitlock,
  ActivationGroup.Bitlink,
  ActivationGroup.Bitreader,
  ActivationGroup.CoworkingTenant,
  ActivationGroup.Member,
  ActivationGroup.Face,
  ActivationGroup.CameraSecurity,
  ActivationGroup.Reception,
  ActivationGroup.Events,
  ActivationGroup.ReservationCalendar_bk,
  ActivationGroup.CoworkingReservationCalendar,
  ActivationGroup.WifiConnector,
  // ここから先は実際には使ってないけど定義だけされているやつ
  ActivationGroup.V2ServiceApplication,
  ActivationGroup.V2OrganizationSetting,
  ActivationGroup.AboutBitkey,
  // app用だからactivationは要らなかったんじゃ…？
  ActivationGroup.V2BitlockApp,
  ActivationGroup.V2ReceptionApp,
];

// 開発中の機能も含めた除外リスト
// 原則お客様環境で出さないものはここに入れる
const activationGroupBlackListWithDev: ActivationGroup[] = [...activationGroupBlackList, ActivationGroup.ThirdPlace];

// 表示メニューを全追加する時の除外リスト
// bM移行とか例外的なものをここに入れる。
export const activationGroupBlackListMax: ActivationGroup[] = [
  ...activationGroupBlackListWithDev,
  // BM移行でしか出してないやつ
  ActivationGroup.BmMigrateAssistant,
  ActivationGroup.BmMigrateTour,
  ActivationGroup.V2SecurityCardGroups,
  ActivationGroup.VisitorGroupManagement,
];

export const featureGroupExistBlackList = (featureGroup: FeatureGroup | undefined) => {
  if (!featureGroup) {
    return false;
  }

  const activationGroups = ActivationFeatureGroupMap[featureGroup];

  return activationGroups.every(v => activationGroupBlackListWithDev.includes(v));
};

export const featureGroupToActivationGroup = (featureGroup: FeatureGroup | undefined) => {
  if (!featureGroup) {
    return false;
  }

  const activationGroups = ActivationFeatureGroupMap[featureGroup];
  if (activationGroups.every(v => activationGroupBlackListWithDev.includes(v))) {
    return undefined;
  }

  return activationGroups.join(',');
};

export class DebuggerService {
  static addActivations = async (activationGroupArray: ActivationGroup[]) => {
    const from = Date.now();
    const to = dayjs(from).add(100, 'years').valueOf();

    const data: ApiTypesAddActivationsPostRequest = {
      activations: activationGroupArray.map(activationGroup => ({
        activationGroup: activationGroup as WActivationGroup,
        from: from,
        to: to,
        serviceProvider: 'workhub',
      })),
    };

    await ActivationService.add(data);
  };

  static deleteActivations = async (activationGroups: ActivationGroup[]) => {
    await ActivationService.delete({activationGroups: activationGroups as WActivationGroup[]});
  };

  static pageReload() {
    // こんな雑なreloadするべきじゃ無いんだけど、Debuggerなので許して欲しい
    window.location.reload();
  }
}
