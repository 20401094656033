import styled from '@emotion/styled';
import * as React from 'react';

import useDict from '@/common/hooks/useDict';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {WHColor} from '@/common/styles/whColor';

const dictDef = {
  required: {
    default: {
      default: '*',
      [Locale.en_US]: '*',
    },
  },
};

const OptionalLabel = styled.div`
  font-size: 12px;
  color: ${WHColor.text.semanticError};
`;

const WRequiredLabel = React.memo(function WRequiredLabel() {
  const dict = useDict(dictDef);
  return <OptionalLabel>{dict.required}</OptionalLabel>;
});

export default WRequiredLabel;
