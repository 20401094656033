import {AxiosRequestConfig} from 'axios';

import {catchStack} from '@/common/error/stacktrace';
import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import {ApiWorkhubCore} from '../apiWorkhubCore';

export type SearchSsoServicesReq = {
  email: string;
};

export type SearchSsoServicesRes = {
  services: {
    saml: {
      idpName: string;
      code: string;
      organizationId: string;
    }[];
    oidc: {
      idpName: string;
      organizationId: string;
    }[];
  };
};

const basePath = '/sso';

export class ApiWorkhubCoreSso {
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config).catch(
      catchStack()
    );

  // 実体はPOSTですがやっていることがGETなので関数名はgetServicesByEmailにしています
  public static getServicesByEmail = (email: string) =>
    ApiWorkhubCoreSso.post<SearchSsoServicesRes, SearchSsoServicesReq>('/services', {email});
}
