import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {NestedReadonly} from '../../types/common-types';
import {Locale, LocaleState, DayjsLocale} from '../state-types/localeStateType';

const initialState: NestedReadonly<LocaleState> = Locale.ja_JP as Locale;

// dayjs の初期値もここで合わせておく
dayjs.locale(DayjsLocale[Locale.ja_JP]);

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<Locale>) => {
      // 変わったらここで dayjs のグローバルロケールも変える
      dayjs.locale(DayjsLocale[action.payload]);
      return action.payload;
    },
    clear: () => initialState,
  },
});

export default localeSlice;
