import clsx from 'clsx';
import { forwardRef, memo, } from 'react';
import styles from '../styles/index.module.css';
const styleMap = {
    color: new Map([
        ['primary', styles.primary],
        ['secondary', styles.secondary],
        ['error', styles.error],
        ['warning', styles.warning],
        ['success', styles.success],
        ['info', styles.info],
    ]),
    fontSize: new Map([
        ['small', styles.small],
        ['medium', styles.medium],
        ['large', styles.large],
    ]),
};
export function createIcon(name, render) {
    const wrap = ({ color = 'inherit', fontSize = 'medium', className, ...additionalProps }, ref) => {
        const props = {
            ...additionalProps,
            className: clsx(styles.icon, styleMap.color.get(color), styleMap.fontSize.get(fontSize), className),
        };
        return render(props, ref);
    };
    const Component = memo(forwardRef(wrap));
    if (process.env.NODE_ENV !== 'production') {
        Component.displayName = name;
    }
    return Component;
}
