import {useMemo} from 'react';

import useDict from '@/common/hooks/useDict';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {ValidationUtil} from '@/common/utils/validationUtil';

/**
 * BKPで登録できるパスワードの最大桁数
 */
export const BKP_MAX_PASSWORD_LENGTH = 56;

/**
 * BKPで登録できるパスワードの最小桁数
 */
export const BKP_MIN_PASSWORD_LENGTH = 8;

const dictDef = {
  empty: {
    default: {
      default: 'パスワードを入力してください',
      [Locale.en_US]: 'Please enter a password.',
    },
  },
  tooShort: {
    default: {
      default: `${BKP_MIN_PASSWORD_LENGTH}桁以上のパスワードを入力してください`,
      [Locale.en_US]: `Please enter a password with at least ${BKP_MIN_PASSWORD_LENGTH} characters.`,
    },
  },
  tooLong: {
    default: {
      default: `${BKP_MAX_PASSWORD_LENGTH}桁以下のパスワードを入力してください`,
      [Locale.en_US]: `Please enter a password with no more than ${BKP_MAX_PASSWORD_LENGTH} characters.`,
    },
  },
  includesWhiteSpace: {
    default: {
      default: 'パスワードに半角スペースが入力されています',
      [Locale.en_US]: 'Password contains white space.',
    },
  },
  invalidCharacters: {
    default: {
      default: '使用できない文字が含まれています。パスワードは半角英数字と記号で入力してください',
      [Locale.en_US]:
        'Invalid characters are included. Please enter the password using alphanumeric characters and symbols.',
    },
  },
  passwordMismatch: {
    default: {
      default: 'パスワードが一致していません',
      [Locale.en_US]: 'Passwords do not match',
    },
  },
  sameWithOldPassword: {
    default: {
      default: '古いパスワードと同じものは利用できません',
      [Locale.en_US]: 'You cannot use the same password as the old one.',
    },
  },
};

export const useValidatePassword = (password: string) => {
  const dict = useDict(dictDef);
  const passwordErrorMessage = useMemo((): string | undefined => {
    if (!password) {
      return dict.empty;
    }
    if (password.length < BKP_MIN_PASSWORD_LENGTH) {
      return dict.tooShort;
    }
    if (password.length > BKP_MAX_PASSWORD_LENGTH) {
      return dict.tooLong;
    }
    if (ValidationUtil.containsWhiteSpace(password)) {
      return dict.includesWhiteSpace;
    }
    if (!ValidationUtil.isValidPassword(password)) {
      return dict.invalidCharacters;
    }
    return undefined;
  }, [dict.empty, dict.includesWhiteSpace, dict.invalidCharacters, dict.tooLong, dict.tooShort, password]);

  return {
    passwordErrorMessage,
  };
};
