import {Locale} from '@/common/redux/state-types/localeStateType';

export const monitoringSectionMenuDef = {
  reports: {
    default: {default: 'レポート', [Locale.en_US]: 'System Reports'},
  },
  logAndHistory: {
    default: {
      default: 'ログ・履歴',
      [Locale.en_US]: 'Log and History',
    },
  },
  spaceUsageReport: {
    default: {
      default: 'スペース利用レポート',
      [Locale.en_US]: 'Space Usage Report',
    },
  },
  spaceUsageReport2: {
    default: {
      default: 'スペース利用レポート2',
      [Locale.en_US]: 'Space Usage Report 2',
    },
  },
  fmDashboard: {
    default: {
      default: 'FMダッシュボード',
      [Locale.en_US]: 'FM Dashboard',
    },
  },
  wEmployeesAttendance: {
    default: {
      default: '出社状況',
      [Locale.en_US]: 'Employees Attendance',
    },
  },
  securityDeviceAlertInquiry: {
    default: {
      default: 'デバイスアラート',
      [Locale.en_US]: 'Alerts',
    },
  },
  sendMailJob: {
    default: {default: 'メール送信履歴', [Locale.en_US]: 'Email History'},
  },
  keyAndTickets: {
    default: {default: 'アプリカギ配信状況', [Locale.en_US]: 'App key delivery Status'},
  },
  faceRecognitionStatus: {
    default: {default: '顔情報配信状況', [Locale.en_US]: 'Face information Status'},
  },
  outlookSyncHistories: {
    default: {
      default: 'Outlook連携ログ',
      [Locale.en_US]: 'Outlook Sync Logs',
    },
  },
  wLockAndUnlockLog: {
    default: {
      default: '解施錠履歴',
      [Locale.en_US]: 'Unlocking / Locking Logs',
    },
  },
  reservationHistory: {
    default: {
      default: '予約履歴',
      [Locale.en_US]: 'Reservation History',
    },
  },
  alertAntiPassBack: {
    default: {
      default: 'アンチパスバックアラート',
      [Locale.en_US]: 'Anti Pass Back Alerts',
    },
  },
};
