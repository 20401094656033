import {
  ApiWorkhubCoreOidcSso,
  StartLoginFlowReq,
  StartLoginFlowWithOrgCodeReq,
  StartSignupFlowReq,
} from '@/v2_api/workhub-core/oidc/sso/apiOidcSso';

export class OidcSsoService {
  public static startLoginFlow = (data: StartLoginFlowReq) =>
    ApiWorkhubCoreOidcSso.startLoginFlow({
      ...data,
    });

  public static startSignupFlow = (data: StartSignupFlowReq) =>
    ApiWorkhubCoreOidcSso.startSignupFlow({
      ...data,
    });

  public static startLoginFlowWithOrgCode = (data: StartLoginFlowWithOrgCodeReq) =>
    ApiWorkhubCoreOidcSso.startLoginFlowWithOrgCode({
      ...data,
    });
}
