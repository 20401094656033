import styled from '@emotion/styled';
import type SvgIcon from '@mui/material/SvgIcon';
import React, {CSSProperties} from 'react';
import {ReactNode} from 'react';
import {makeStyles} from 'tss-react/mui';

import {WHColor} from '@/common/styles/whColor';
import WOptionalChip from '@/components/chip/WOptionalChip';
import WRequiredChip from '@/components/chip/WRequiredChip';

import WOptionalLabel from './WOptionalLabel';
import WRequiredLabel from './WRequiredLabel';

const Wrapper = styled.div<{
  margintop?: number | string;
  marginleft?: number | string;
  flex?: number | string;
  marginBottom?: number | string;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
}>`
  margin-top: ${props => (typeof props.margintop === 'number' ? `${props.margintop}px` : props.margintop)};
  margin-left: ${props => (typeof props.marginleft === 'number' ? `${props.marginleft}px` : props.marginleft)};
  margin-bottom: ${props => (typeof props.marginBottom === 'number' ? `${props.marginBottom}px` : props.marginBottom)};
  box-sizing: border-box;
  flex: ${props => props.flex};
  width: ${props => (props.width ? props.width : 'auto')};
  max-width: ${props => props.maxWidth && props.maxWidth};
  min-width: ${props => props.minWidth && props.minWidth};
`;

const Label = styled.div<{error?: boolean; bold?: boolean}>`
  white-space: pre;
  font-weight: ${props => (props.bold ? 700 : 500)};
  font-size: ${props => (props.bold ? '16px' : '14px')};
  line-height: 20px;
  color: ${props =>
    props.error ? WHColor.text.semanticError : props.bold ? WHColor.text.brandPrimary : WHColor.text.neutralSecondary};
`;

const LabelWrapper = styled.div<{
  minHeight?: number | string;
}>`
  display: flex;
  align-items: center;
  min-height: ${props => (typeof props.minHeight === 'number' ? `${props.minHeight}px` : props.minHeight)};
`;

const RightLabel = styled.div`
  margin-left: auto;
`;

const Summary = styled.p<{
  color?: string;
  margin?: CSSProperties['margin'];
}>`
  margin: ${({margin}) => margin ?? '14px 0'};
  font-size: 12px;
  /*
    adminのダイアログのデザインルールとして、
    項目名のラベルのテキストカラーはWHColor.text.neutralPrimary
    サマリーのテキストカラーはWHColor.text.neutralSecondaryとなっている。
    場所によっては濃淡が逆で使われている箇所もあるため、開発側は注意が必要
    必要に応じて、propsでカラーを指定すればよい
   */
  color: ${props => (props.color ? props.color : WHColor.text.neutralSecondary)};
  white-space: pre-wrap;
`;

const Content = styled.div<{marginTop: number | undefined; fontSize?: number; height?: number}>`
  margin-top: ${props => (props.marginTop !== undefined ? `${props.marginTop}px` : '8px')};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '16px')};
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
`;

const useStyles = makeStyles()({
  errorIcon: {
    marginRight: 8,
    color: WHColor.object.semanticError,
    fontSize: 16,
  },
});

type WLabelContentProps = {
  label: string;
  content: ReactNode;
  summary?: string;
  summaryColor?: string;
  summaryMargin?: CSSProperties['margin'];
  error?: boolean;
  errorIcon?: typeof SvgIcon | React.FC;
  right?: ReactNode;
  rightProps?: React.ComponentProps<typeof RightLabel>;
  required?: boolean;
  optional?: boolean;
  useChip?: boolean;
  optionalHeader?: ReactNode;
  icon?: ReactNode;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  marginTop?: number | string;
  marginLeft?: number | string;
  marginBottom?: number | string;
  // TODO あんまりつけたくはなかったが、タグをつけたときに高さがずれる時があるのでやむなく
  minLabelHeight?: number;
  // コンテントの中身が16pxで固定されるのが困るため
  contentFontSize?: number;
  contentMarginTop?: number;
  flex?: number | string;
  labelBold?: boolean;
  dataTestId?: string;
};

const WLabelContent = React.memo<WLabelContentProps>(
  ({
    label,
    content,
    summary,
    summaryColor,
    summaryMargin,
    error,
    errorIcon: ErrorIcon,
    right,
    rightProps,
    required,
    optional,
    useChip,
    optionalHeader,
    icon,
    width,
    minWidth,
    maxWidth,
    marginTop,
    marginLeft,
    marginBottom,
    minLabelHeight,
    contentFontSize,
    contentMarginTop,
    flex,
    labelBold,
    dataTestId,
  }) => {
    const {classes} = useStyles();
    // WRequiredLabel/WOptionalLabelは古いラベルなので基本的にuseChipをつける
    const useRequiredChip = React.useMemo(() => {
      if (useChip) {
        return <WRequiredChip />;
      } else {
        return <WRequiredLabel />;
      }
    }, [useChip]);
    const useOptionalChip = React.useMemo(() => {
      if (useChip) {
        return <WOptionalChip />;
      } else {
        return <WOptionalLabel />;
      }
    }, [useChip]);

    return (
      <Wrapper
        margintop={marginTop}
        marginleft={marginLeft}
        marginBottom={marginBottom}
        flex={flex}
        width={width}
        minWidth={minWidth}
        maxWidth={maxWidth}
        data-testid={dataTestId ?? 'rootWLabelContent'}
      >
        <LabelWrapper minHeight={minLabelHeight}>
          {!!ErrorIcon && <ErrorIcon className={classes.errorIcon} />}
          <Label error={error} bold={labelBold}>
            {label}
          </Label>
          {/* WRequiredLabel/WOptionalLabelは古いラベルなので基本的にuseChipをつける*/}
          {required && <div>{useRequiredChip}</div>}
          {optional && <div>{useOptionalChip}</div>}
          {optionalHeader && <RightLabel>{optionalHeader}</RightLabel>}
          <div>{icon}</div>
          {right && <RightLabel {...rightProps}>{right}</RightLabel>}
        </LabelWrapper>
        {!!summary && (
          <Summary color={summaryColor} margin={summaryMargin}>
            {summary}
          </Summary>
        )}
        <Content fontSize={contentFontSize} marginTop={contentMarginTop}>
          {content}
        </Content>
      </Wrapper>
    );
  }
);

WLabelContent.displayName = 'WLabelContent';
export default WLabelContent;
