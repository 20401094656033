import {
  ApiTypesAuthPostAuthorityRequest,
  ApiTypesAuthPostAuthorityResponse,
  ApiTypesAuthPostTokenAuthorityRequest,
  ApiTypesAuthPostTokenAuthorityResponse,
  ApiTypesAuthPostPasswordIssueResetTokenRequest,
  ApiTypesAuthPostPasswordIssueResetTokenResponse,
  ApiTypesAuthPostVerifyRequest,
  ApiTypesAuthPostVerifyResponse,
  ApiTypesRefreshAccessTokenPostRequest,
  ApiTypesRefreshAccessTokenPostResponse,
  ApiTypesAuthPostTokenForMobileRequest,
  ApiTypesAuthPostTokenForMobileResponse,
  ApiTypesAuthPostForMobileResponse,
  ApiTypesAuthPostForMobileRequest,
  ApiTypesAuthPostResetPasswordResponse,
  ApiTypesAuthPostResetPasswordRequest,
} from '@bitkey-service/workhub-types/lib/api/workhub-account-api-types/auth/apiTypesAuth';
import {AxiosRequestConfig} from 'axios';

import {catchStack} from '@/common/error/stacktrace';
import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import {ApiAccount} from '../apiAccount';

const basePath = '/auth';

export type MailTemplate = ApiTypesAuthPostPasswordIssueResetTokenRequest['mailTemplate'];

export class ApiAccountAuth {
  public static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiAccount.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config).catch(catchStack());

  public static loginWithEmailAndAuthority = (data: ApiTypesAuthPostAuthorityRequest) =>
    ApiAccountAuth.post<ApiTypesAuthPostAuthorityResponse, ApiTypesAuthPostAuthorityRequest>('/authority', data);

  public static loginWithEmailForMobile = (data: ApiTypesAuthPostForMobileRequest) =>
    ApiAccountAuth.post<ApiTypesAuthPostForMobileResponse, ApiTypesAuthPostForMobileRequest>('/mobile', data);

  public static loginWithTokenAndAuthority = () =>
    ApiAccountAuth.post<ApiTypesAuthPostTokenAuthorityResponse, ApiTypesAuthPostTokenAuthorityRequest>(
      '/token/authority',
      {}
    );

  public static loginWithTokenForMobile = () =>
    ApiAccountAuth.post<ApiTypesAuthPostTokenForMobileResponse, ApiTypesAuthPostTokenForMobileRequest>(
      '/token/mobile',
      {}
    );

  public static verify = () =>
    ApiAccountAuth.post<ApiTypesAuthPostVerifyResponse, ApiTypesAuthPostVerifyRequest>('/verify', {});

  public static refreshAccessToken = (param: ApiTypesRefreshAccessTokenPostRequest) =>
    ApiAccountAuth.post<ApiTypesRefreshAccessTokenPostResponse, ApiTypesRefreshAccessTokenPostRequest>(
      '/refreshAccessToken',
      param
    );

  public static issueBulkPasswordResetToken = (email: string, mailTemplate: MailTemplate) =>
    ApiAccountAuth.post<
      ApiTypesAuthPostPasswordIssueResetTokenResponse,
      ApiTypesAuthPostPasswordIssueResetTokenRequest
    >('/password/bulk-reset-token', {email, mailTemplate});

  public static bulkResetPassword = async (password: string, bulkResetToken: string, mailTemplate: MailTemplate) =>
    ApiAccountAuth.post<ApiTypesAuthPostResetPasswordResponse, ApiTypesAuthPostResetPasswordRequest>(
      '/password/bulk-reset',
      {password, bulkResetToken, mailTemplate}
    );
}
