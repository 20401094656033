// generated-by-gen
import type {AxiosResponse} from 'axios';

import {getCoreAxios} from '@/common/axios/getCoreAxios';
import {axiosCatch} from '@/common/error/axiosError';
import {getStackTrace} from '@/common/error/stacktrace';
import Logger from '@/common/logger/logger';

const logger = Logger.create('searchSsoServicesByOrgCodeApi');

export const searchSsoServicesByOrgCodeApi = async (
  arg: {query: SearchSsoServicesByOrgCodeQueryParam},
  opts?: {region?: 'default' | 'asia'}
) => {
  const axios = getCoreAxios(opts);
  axios.interceptors.response.use((response: AxiosResponse) => {
    logger.trace(response.request?.responseURL, response);
    return response;
  });
  return await axios
    .get<SearchSsoServicesByOrgCodeResponse>(`/sso/services`, {
      params: arg.query,
    })
    .catch(axiosCatch(getStackTrace()));
};

export type SearchSsoServicesByOrgCodeResponse = {
  services: ({
    organizationId: string;
  } & (
    | {
        type: 'oidc';
        idpName: 'hhCross' | 'hillsId';
      }
    | {
        type: 'saml';
        /**
         * BKPへリダイレクトしてSAMLフローを開始するときに組織を識別するために必要なコード
         * https://scim.bitkey.tokyo/sso/<code> にリダイレクトすることでSAMLログインができる
         *
         */
        code: string;
      }
  ))[];
};
export type SearchSsoServicesByOrgCodeQueryParam = {
  orgCode: string;
};
