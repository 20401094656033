import dayjs from 'dayjs';
import fromPairs from 'lodash/fromPairs';

export const CookieKey = {
  accessToken: 'accessToken',
} as const;

export type CookieSetOptions = {
  key: string;
  value: string;
};

export type CookieKey = (typeof CookieKey)[keyof typeof CookieKey];

export const getCookieValueByKey = (key: string): string | undefined => {
  const cookies = window.document.cookie.split('; ');

  const cookieObject = fromPairs(cookies.map(v => v.split('=')));
  return cookieObject[key];
};

export const removeCookieValueByKey = (key: string): void => {
  const cookies = window.document.cookie.split('; ');
  const rejectedCookies = cookies.find(v => {
    const [cookieKey] = v.split('=');
    return cookieKey === key;
  });
  if (rejectedCookies) {
    window.document.cookie = [rejectedCookies, 'max-age=0'].join('; ');
  }
};

export const addCookieValueByKey = ({key, value}: CookieSetOptions, days?: number): void => {
  let expires = '';
  if (days) {
    const date = dayjs().add(days, 'day').toDate();
    expires = '; expires=' + date.toUTCString();
  }
  const cookieValue = encodeURIComponent(value);
  window.document.cookie = `${key}=${cookieValue}${expires}; path=/`;
};
