import {Dispatch} from '@reduxjs/toolkit';

import {getOrgOverridedExperimentalFeaturesApi} from '@/api-call/workhub-core/getOrgOverridedExperimentalFeaturesApi';
import {experimentalFeaturesSlice} from '@/common/redux/slices/experimentalFeaturesSlice';

export async function initExperimentalFeatures(dispatch: Dispatch<any>) {
  const res = await getOrgOverridedExperimentalFeaturesApi({region: 'asia'});
  const expFeatures = res.data;
  const featureMap = expFeatures.reduce((acc, expFeature) => {
    acc[expFeature.code] = expFeature.enabled;
    return acc;
  }, {});

  dispatch(experimentalFeaturesSlice.actions.set(featureMap));
}
